// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rea-header {
  margin-bottom: 0;
  align-items: center;
}

.username {
  padding-bottom: 0.5625rem;
  padding-top: 0.5625rem;
  pointer-events: none;
}

.left-side-block {
  display: flex;
  justify-content: center;
  align-items: center;
}
.left-side-block .hamburger-menu {
  width: 32px;
  height: 32px;
  margin-right: 20px;
}
.left-side-block .hamburger-menu #hamburger-menu {
  position: absolute;
  cursor: pointer;
  opacity: 0;
  width: 32px;
  height: 32px;
}
.left-side-block .hamburger-menu .icon {
  width: 32px;
  height: 32px;
}
.left-side-block .cmp-brand {
  padding-top: 0 !important;
}
.left-side-block .cmp-brand .elm-image .is-logo {
  width: 50px !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
