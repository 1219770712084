import { ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { SectionBase } from '../section.base';
import { SectionName } from '../../enums/order-enums';
import { RailOrder, CommercialSpecification } from '../../models/api-railorder';
import { NewOrderMainComponent } from '../../new-order-main/new-order-main.component';
import { DynamicStorageService } from '../../service/dynamic-session-storage.service';
import { KeyValuePair, RAILORDER_COMMERCIAL_CURRENCY_LISTNAME } from '../../models/api-dynamic-storage';

@Component({
  selector: 'app-new-order-commercial',
  templateUrl: './new-order-commercial.component.html',
  styleUrls: ['./new-order-commercial.component.scss']
})

export class NewOrderCommercialComponent extends SectionBase implements OnInit {
  @Input() currentSectionName: SectionName;

  protected SectionName = SectionName;
  protected parent: NewOrderMainComponent;
  protected formGroup: FormGroup;
  protected isAdditionalPrepaymentInput: boolean;
  protected isCommercialSpecification: boolean;
  protected isAdditionalCommercialInformation: boolean;
  protected isAnnotationOfConsignorDescription: boolean;
  protected isDbInternal: boolean;
  protected isSectionalInvoicing: boolean;
  protected commercialSpecifications: CommercialSpecification[] = [];
  protected currencyList: KeyValuePair[] = [];
  protected railOrder: RailOrder;

  private RAILORDER_COMMERCIAL_SPECIFICATION_LISTNAME: string = 'RAILORDER_COMMERCIAL_SPECIFICATION_LIST';

  constructor(private injector: Injector, private formBuilder: FormBuilder, private dynamicStorageService: DynamicStorageService, private cd: ChangeDetectorRef) {
    super();
    this.createMockup();
    this.parent = this.injector.get<NewOrderMainComponent>(NewOrderMainComponent);
    this.createForm();
  }

  ngOnInit(): void {
    this.retrieveDataFromSession();
    this.subscribeToFormChanges();
  }

  public updateRailOrder(ro: RailOrder) {
    console.log(ro);
    this.railOrder = ro;
    this.setFormValues();
    this.cd.detectChanges();
  }

  private createMockup(): void {
    this.dynamicStorageService.removeKeyValueList(this.RAILORDER_COMMERCIAL_SPECIFICATION_LISTNAME);
    const dataList: KeyValuePair[] = [];
    const item: KeyValuePair = {
      key: '0000',
      value: 'Auswahl'
    }
    dataList.push(item);
    for (let i = 1; i < 5; i++) {
      const item: KeyValuePair = {
        key: '000' + i,
        value: '000' + i,
      }
      dataList.push(item);
    }
    this.dynamicStorageService.storeKeyValueList(this.RAILORDER_COMMERCIAL_SPECIFICATION_LISTNAME, dataList);
  }

  private subscribeToFormChanges(): void {
    // Subscribe to form changes only triggered by user input
    this.formGroup.valueChanges.subscribe((changes) => {
      // Check if the form is touched or dirty to ensure that only user input triggers the update
      if (this.formGroup.dirty || this.formGroup.touched) {
        this.updateRailOrderFromForm(changes);
      }
    });
  }
  
  private updateRailOrderFromForm(changes: any): void {
    // Dynamically update the railOrder based on form changes
    this.railOrder = {
        ...this.railOrder,

        // Updating CommercialTransportConditions, including PrepaymentUpTo
        commercialTransportConditions: {
            ...this.railOrder.commercialTransportConditions,
            prepaymentNote: changes.prepaymentNote ?? this.railOrder.commercialTransportConditions?.prepaymentNote,
            discountCode: changes.commercialInformationDiscountCode ?? this.railOrder.commercialTransportConditions.discountCode,
            valueOfDelivery: {
                price: changes.commercialInformationValueOfDeliveryPrice ?? this.railOrder.commercialTransportConditions?.valueOfDelivery?.price ?? null, // Default to null if not provided
                currency: changes.commercialInformationValueOfDeliveryCurrency ?? this.railOrder.commercialTransportConditions?.valueOfDelivery?.currency ?? '', // Default to empty string
            },
            valueOfCommodity: {
                price: changes.commercialInformationValueOfCommodityPrice ?? this.railOrder.commercialTransportConditions?.valueOfCommodity?.price ?? null, // Default to null if not provided
                currency: changes.commercialInformationValueOfCommodityCurrency ?? this.railOrder.commercialTransportConditions?.valueOfCommodity?.currency ?? '', // Default to empty string
            },
            prepaymentUpTo: {
                ...this.railOrder.commercialTransportConditions?.prepaymentUpTo,
                authority: changes.prepaymentUpToAuthority ?? this.railOrder.commercialTransportConditions?.prepaymentUpTo?.authority,
                locationCode: changes.prepaymentUpToLocationCode ?? this.railOrder.commercialTransportConditions?.prepaymentUpTo?.locationCode,
                locationName: changes.prepaymentUpToLocationName ?? this.railOrder.commercialTransportConditions?.prepaymentUpTo?.locationName,
                borderCode: changes.prepaymentUpToBorderCode ?? this.railOrder.commercialTransportConditions?.prepaymentUpTo?.borderCode,
                borderName: changes.prepaymentUpToBorderName ?? this.railOrder.commercialTransportConditions?.prepaymentUpTo?.borderName,
                borderDescription: changes.prepaymentUpToBorderDescription ?? this.railOrder.commercialTransportConditions?.prepaymentUpTo?.borderDescription,
            },
            // Sectional Invoicings
            sectionalInvoicings: changes.sectionalInvoicings?.map((sectionalInvoicing, index) => ({
                sectionalInvoicingCarrierCode: changes.sectionalInvoicingCarrierCode ?? this.railOrder.commercialTransportConditions?.sectionalInvoicings?.[index]?.sectionalInvoicingCarrierCode,
                sectionalInvoicingCarrierName: sectionalInvoicing.sectionalInvoicingCarrierName ?? this.railOrder.commercialTransportConditions?.sectionalInvoicings?.[index]?.sectionalInvoicingCarrierName,
                startAuthority: sectionalInvoicing.startAuthority ?? this.railOrder.commercialTransportConditions?.sectionalInvoicings?.[index]?.startAuthority,
                endAuthority: sectionalInvoicing.endAuthority ?? this.railOrder.commercialTransportConditions?.sectionalInvoicings?.[index]?.endAuthority,
                executingCarrierRUCode: changes.executingCarrierRuCode ?? sectionalInvoicing.executingCarrierRUCode ?? this.railOrder.commercialTransportConditions?.sectionalInvoicings?.[index]?.executingCarrierRUCode,
                executingCarrierName: sectionalInvoicing.executingCarrierName ?? this.railOrder.commercialTransportConditions?.sectionalInvoicings?.[index]?.executingCarrierName,
                freightpayerTransit: {
                    ...this.railOrder.commercialTransportConditions?.sectionalInvoicings?.[index]?.freightpayerTransit,
                    name: sectionalInvoicing.freightpayerTransit?.name ?? this.railOrder.commercialTransportConditions?.sectionalInvoicings?.[index]?.freightpayerTransit?.name,
                    customerId: sectionalInvoicing.freightpayerTransit?.customerId ?? this.railOrder.commercialTransportConditions?.sectionalInvoicings?.[index]?.freightpayerTransit?.customerId,
                    partnerId: sectionalInvoicing.freightpayerTransit?.partnerId ?? this.railOrder.commercialTransportConditions?.sectionalInvoicings?.[index]?.freightpayerTransit?.partnerId,
                }
            })) ?? this.railOrder.commercialTransportConditions?.sectionalInvoicings,
        },

        // Handling the specialTreatmentOrders array
        specialTreatmentOrders: changes.specialTreatmentOrders?.map((order: any) => ({
            productName: order.productName ?? '',
            info: order.info ?? '',
            productExtraChargeCode: changes.productExtraChargeCode?.map((item: any) => item.productExtraChargeCode) ?? [],
            authority: order.authority ?? null,
            locationCode: order.locationCode ?? '',
            locationName: order.locationName ?? '',
            includedInPrepaymentNote: order.includedInPrepaymentNote ?? false,
            specialTreatmentChargings: order.specialTreatmentChargings?.map((charging: any) => ({
                startAuthority: charging.startAuthority ?? null,
                startLocationCode: charging.startLocationCode ?? '',
                startBorderCode: charging.startBorderCode ?? null,
                endAuthority: charging.endAuthority ?? null,
                endLocationCode: charging.endLocationCode ?? '',
                endBorderCode: charging.endBorderCode ?? null,
                prepayment: charging.prepayment ?? '',
                amount: charging.amount ?? null,
            })) ?? [],
            specialTreatmentOrderWagonPositions: order.specialTreatmentOrderWagonPositions?.map((pos: number) => pos) ?? [],
            specialTreatmentOrderUTIs: order.specialTreatmentOrderUTIs?.map((uti: any) => ({
                utiId: uti.utiId ?? null,
                location: uti.location ?? '',
            })) ?? [],
            productionNode: {
                nodeName: order.productionNodeNodeName ?? '',
                nodeCode: order.productionNodeNodeCode ?? '',
            },
        })) ?? this.railOrder.specialTreatmentOrders,

        // Handling specialAnnotations
        specialAnnotations: {
            annotationOfConsignorDescription: changes.specialAnnotations?.annotationOfConsignorDescription ?? '',
            additionalDeclarationOfCarrier: changes.specialAnnotations?.additionalDeclarationOfCarrier ?? '',
            consignorDeclarations: changes.specialAnnotations?.consignorDeclarations?.map((declaration: any) => ({})) ?? [],
            commercialSpecifications: changes.specialAnnotations?.commercialSpecifications?.map((specification: any) => ({
                commercialSpecificationCode: specification.commercialSpecificationCode ?? '',
                commercialSpecificationDescription: specification.commercialSpecificationDescription ?? '',
                commercialSpecificationAdditionalInfo: specification.commercialSpecificationAdditionalInfo ?? '',
            })) ?? [],
            carrierDeclarations: changes.specialAnnotations?.carrierDeclarations?.map((declaration: any) => ({})) ?? [],
        },

        // Handling TakeOverConditions
        handOverConditions: {
            typeOfTakeover: changes.takeOverConditions?.typeOfTakeover ?? this.railOrder.handOverConditions?.typeOfTakeover ?? null,
            shipOwner: changes.takeOverConditions?.shipOwner ?? this.railOrder.handOverConditions?.shipOwner ?? '',
            shipName: changes.takeOverConditions?.shipName ?? this.railOrder.handOverConditions?.shipName ?? '',
            arrival: changes.takeOverConditions?.arrival ?? this.railOrder.handOverConditions?.arrival ?? undefined,
            originPort: changes.takeOverConditions?.originPort ?? this.railOrder.handOverConditions?.originPort ?? '',
            loadingAuthorisation: changes.takeOverConditions?.loadingAuthorisation ?? this.railOrder.handOverConditions?.loadingAuthorisation ?? '',
        },

        // Update External Reference Consignment Number
        externalReferences: this.railOrder.externalReferences?.map(ref => {
            if (ref.type === 'ALY') {
                return {
                    ...ref,
                    identifier: changes.commercialInformationExternalReferenceConsigmentNumber ?? ref.identifier
                };
            }
            return ref;
        }) ?? (() => {
            // If no 'ALY' reference exists, create one
            if (changes.commercialInformationExternalReferenceConsigmentNumber) {
                return [{
                    type: 'ALY',
                    identifier: changes.commercialInformationExternalReferenceConsigmentNumber
                }];
            }
            return [];
        })(),

        // Update other commercial information fields
        contractNumber: changes.commercialInformationContractNumber ?? this.railOrder.contractNumber,
    };

    // Trigger change detection manually if necessary
    this.cd.detectChanges();
}

  private retrieveDataFromSession(): void {
    this.dynamicStorageService.retrieveKeyValueList(this.RAILORDER_COMMERCIAL_SPECIFICATION_LISTNAME).subscribe((dataList: KeyValuePair[]) => {
      for (let item of dataList) {
        const commercialSpecification: CommercialSpecification = {
          code: item.key,
          description: item.value
        }
        this.commercialSpecifications.push(commercialSpecification);
      }
    });

    this.dynamicStorageService.retrieveKeyValueList(RAILORDER_COMMERCIAL_CURRENCY_LISTNAME)
      .subscribe({
        next: data => this.currencyList = data || [],
        error: err => console.error(`Failed to load authority list: ${err}`)
      });
  }

  private setFormValues(): void {
    const item: KeyValuePair = {
      key: '0000',
      value: 'Auswahl'
    }
    const commercialSpecification: CommercialSpecification = {
      code: item.key,
      description: item.value
    }
    this.addCommercialSpecification(commercialSpecification);
    /*for (let control of this.commercialSpecification.controls) {
      control.setValue(item);
    }*/

  }

  private createProductExtraChargeCodes() {
    const productExtraChargeCodes = this.formGroup.get('productExtraChargeCodes') as FormArray;
    for (let i = 0; i < 5; i++) {
      const itemGroup: FormGroup = this.formBuilder.group({
        productExtraChargeCode: new FormControl('')
      });
      productExtraChargeCodes.push(itemGroup);
    }
  }
  private createForm(): void {
    this.formGroup = new FormGroup({
      prepaymentNote: new FormControl(''),
      productExtraChargeCodes: new FormArray([]),
      prepaymentUpToAuthority: new FormControl(''),
      prepaymentUpToBorderDescription: new FormControl(''),
      commercialSpecification: this.formBuilder.array([]),
      loadingAuthorisation: new FormControl(''),
      commercialInformationNhmCode: new FormControl(''),
      commercialInformationContractNumber: new FormControl(''),
      commercialInformationDiscountCode: new FormControl(''),
      commercialInformationValueOfCommodityPrice: new FormControl(''),
      commercialInformationValueOfCommodityCurrency: new FormControl(''),
      commercialInformationValueOfDeliveryPrice: new FormControl(''),
      commercialInformationValueOfDeliveryCurrency: new FormControl(''),
      consignorDescriptionTextarea: new FormControl(''),
      commercialInformationExternalReferenceIdentifier: new FormControl(''),
      commercialInformationExternalReferenceConsigmentNumber: new FormControl(''),
      executingCarrierRuCode: new FormControl(''),
      sectionalInvoicingCarrierCode: new FormControl('')
    });
    this.createProductExtraChargeCodes();
  }

  protected deleteItem(idx: number): void {
    if (this.commercialSpecification.length > 1) {
      this.commercialSpecification.removeAt(idx);
    }
  }


  protected addProdcutExtraChargeCode(item?: number): void {
    const itemGroup: FormGroup = this.formBuilder.group({});
    if (item) {
      itemGroup.addControl('productExtraChargeCode', new FormControl(item));
    } else {
      itemGroup.addControl('productExtraChargeCode', new FormControl());
    }
  }

  protected addCommercialSpecification(item?: CommercialSpecification): void {
    const itemGroup: FormGroup = this.formBuilder.group({});
    if (item) {
      itemGroup.addControl('commercialSpecificationAdditionalInfo', new FormControl(item.additionalInformation));
      itemGroup.addControl('commercialSpecificationCode', new FormControl(item.code));
      itemGroup.addControl('commercialSpecificationDescription', new FormControl(item.description));
    } else {
      itemGroup.addControl('commercialSpecificationAdditionalInfo', new FormControl());
      itemGroup.addControl('commercialSpecificationCode', new FormControl());
      itemGroup.addControl('commercialSpecificationDescription', new FormControl());
    }
    this.commercialSpecification.push(itemGroup);
  }

  public validate(): string[] {
    console.log('validate consignor-consignee section');
    return [];
  }

  protected toggleSectionalInvoicing(): void {
    this.isSectionalInvoicing = !this.isSectionalInvoicing;
  }

  protected toggleAdditionalPrepaymentInput(): void {
    this.isAdditionalPrepaymentInput = !this.isAdditionalPrepaymentInput;
  }

  protected toggleCommercialSpecification(): void {
    this.isCommercialSpecification = !this.isCommercialSpecification;
  }

  protected toggleAdditionalCommercialInformation(): void {
    this.isAdditionalCommercialInformation = !this.isAdditionalCommercialInformation;
  }

  protected toggleAnnotationOfConsignorDescription(): void {
    this.isAnnotationOfConsignorDescription = !this.isAnnotationOfConsignorDescription;
  }

  protected toggleDbInternal(): void {
    this.isDbInternal = !this.isDbInternal;
  }

  protected get prepaymentNote(): FormControl {
    return this.formGroup.get('prepaymentNote') as FormControl;
  }

  protected get productExtraChargeCodes(): FormArray {
    return this.formGroup.get('productExtraChargeCodes') as FormArray;
  }

  protected get prepaymentUpToAuthority(): FormControl {
    return this.formGroup.get('prepaymentUpToAuthority') as FormControl;
  }

  protected get prepaymentUpToBorderDescription(): FormControl {
    return this.formGroup.get('prepaymentUpToBorderDescription') as FormControl;
  }

  protected get commercialSpecification(): FormArray {
    return this.formGroup.get('commercialSpecification') as FormArray;
  }

  protected get loadingAuthorisation(): FormControl {
    return this.formGroup.get('loadingAuthorisation') as FormControl;
  }

  protected get commercialInformationNhmCode(): FormControl {
    return this.formGroup.get('commercialInformationNhmCode') as FormControl;
  }

  protected get commercialInformationContractNumber(): FormControl {
    return this.formGroup.get('commercialInformationContractNumber') as FormControl;
  }

  protected get commercialInformationDiscountCode(): FormControl {
    return this.formGroup.get('commercialInformationDiscountCode') as FormControl;
  }

  protected get commercialInformationValueOfCommodityPrice(): FormControl {
    return this.formGroup.get('commercialInformationValueOfCommodityPrice') as FormControl;
  }

  protected get commercialInformationValueOfDeliveryPrice(): FormControl {
    return this.formGroup.get('commercialInformationValueOfDeliveryPrice') as FormControl;
  }

  protected get commercialInformationExternalReferenceIdentifier(): FormControl {
    return this.formGroup.get('commercialInformationExternalReferenceIdentifier') as FormControl;
  }

  protected get commercialInformationExternalReferenceConsigmentNumber(): FormControl {
    return this.formGroup.get('commercialInformationExternalReferenceConsigmentNumber') as FormControl;
  }

  protected get executingCarrierRuCode(): FormControl {
    return this.formGroup.get('executingCarrierRuCode') as FormControl;
  }

  protected get sectionalInvoicingCarrierCode(): FormControl {
    return this.formGroup.get('sectionalInvoicingCarrierCode') as FormControl;
  }

  protected onChangeAdditionalCommercialInformationValueOfDeliveryCurrency($event: Event) {
    throw new Error('Method not implemented.');
  }
  protected onChangeAdditionalCommercialInformationValueOfCommodityCurrency($event: Event) {
    throw new Error('Method not implemented.');
  }
}