import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { NewOrderService } from './new-order.service';
import { MatDialog } from '@angular/material/dialog';
import { NewOrderMainComponent } from '../new-order-main/new-order-main.component';
import { initialRailOrder, RailOrder } from '../models/api-railorder';
import { OrderInfoData } from '../models/order-info-data.model';

export const NEW_ORDER_DAILOG_WIDTH = 1710;

@Injectable({
  providedIn: 'root'
})
export class NewOrderDialogService implements OnDestroy {

  private destroy$ = new Subject<void>();

  constructor(
    private newOrderService: NewOrderService,
    private dialog: MatDialog // Inject MatDialog here
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  showNewOrderDialog(orderId: number | null): void {
    if(!orderId){
      const orderInfoData: OrderInfoData = {
        isNew: true,
        railOrder: initialRailOrder()
      };
      this.openModalWindow(NewOrderMainComponent, orderInfoData);
      return;
    }
    
    this.getOrder(orderId).pipe(takeUntil(this.destroy$)).subscribe(result => {
      this.openModalWindow(NewOrderMainComponent, {railOrder: result} as OrderInfoData);
    });
  }

  private getOrder(orderId: number): Observable<RailOrder> {
    return this.newOrderService.getOrder(orderId);
  }

  private openModalWindow(component: any, data: any): void {
    const ref = this.dialog.open(component, {
      data: data,
      width: NEW_ORDER_DAILOG_WIDTH + 'px',  // Adjust the width as needed
      //maxWidth: '95vw',
      //height: 'auto',
      //height: '700px',
      height: '90vh', // Ensure dialog height doesn't exceed viewport height
      disableClose: true,  // Prevent closing the modal by clicking outside
    });
    ref.addPanelClass('modal-fullscreen');    
    ref.afterClosed().subscribe(result => { console.log(result) })
  }

}
