import { Component, Input, OnDestroy, OnInit, SimpleChanges, inject } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { WagonInformation, ExternalReference, initalWagonInformation, RailOrder, initialExternalReference } from "../../../../models/api-railorder";
import { WagonDataCommunicationService } from "../../service/wagon-data-communication.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-customer-reference',
  templateUrl: './customer-reference.component.html',
  styleUrls: ['./customer-reference.component.scss']
})

export class CustomerReferenceComponent implements OnInit, OnDestroy {
  @Input() railOrder: RailOrder;
  private wagonInformation: WagonInformation;
  protected formGroup: FormGroup; // The main form group
  private wagonDataCommunicationService: WagonDataCommunicationService = inject(WagonDataCommunicationService);
  private sub: Subscription = new Subscription();

  constructor(private fb: FormBuilder) { }

  //#region CreateFormAndFillForm
  ngOnInit(): void {
    this.initForm();
    this.sub.add(
      this.wagonDataCommunicationService.currentWagonInformation$.subscribe({
        next: obj => {
          if (obj.componentName == this.constructor.name) {
            return;
          }
          this.wagonInformation = obj.wagonInformation ? obj.wagonInformation : initalWagonInformation();
          console.log("wagonInformation changes read in customer-reference list component", this.wagonInformation);
          this.addLinesToForm();
        }
      }));
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  //#region CreateForm
  private initForm(): void {

    this.formGroup = this.fb.group({
      customerReferenceList: this.fb.array([]) // Form array for customer references
    });
    this.sub.add(
      this.formGroup.valueChanges.subscribe(() => {
        console.log(' this.formGroup.valueChanges.subscribe')
        if (this.formGroup.valid) {
          this.valueChangeCallback();
        }
      }));
  }

  private valueChangeCallback() {
    console.log('valueChangeCallback()')
    this.wagonInformation.externalReferences = this.getFormValues();
    this.wagonDataCommunicationService.changeWagonInformation(this.wagonInformation, this.constructor.name);
  }

  private addLinesToForm() {
    if (this.wagonInformation.externalReferences.length === 0) {
      this.addNewLine(null);
    } else if (this.wagonInformation.externalReferences.length != this.customerReferenceList.length) {
      this.setFormValues();
    }
  }
  //#endregion

  //#region Fillform

  setFormValues() {
    // Clear the form array before setting new values
    if (this.customerReferenceList && this.customerReferenceList.length > 0) {
      this.customerReferenceList.clear();
    }

    // Check if externalReferences exist in the wagon information
    if (this.wagonInformation && this.wagonInformation.externalReferences?.length) {
      // Loop through each external reference and add it to the form if type is "CRR"
      this.wagonInformation.externalReferences.forEach((item: ExternalReference) => {
        if (item.type === "CRR") {
          this.addNewLine(item);  // Pass the "CRR" item to addNewLine
        }
      });
    } else {
      // If no externalReferences, add an empty line with type "CRR"
      this.addNewLine();
    }
  }
  //#endregion

  //#region Filldata
  protected getFormValues(): ExternalReference[] {
    const formValues: ExternalReference[] = [];

    this.customerReferenceList.controls.forEach((group: FormGroup) => {
      const item: ExternalReference = {
        type: group.get('type')?.value,
        identifier: group.get('identifier')?.value,
      };
      formValues.push(item);
    });
    console.log('getFormValues()', formValues)
    return formValues;
  }
  //#endregion

  //#region Getter
  protected get customerReferenceList(): FormArray {
    return this.formGroup?.get('customerReferenceList') as FormArray;
  }

  protected getType(i: number): FormControl {
    return this.getControl(i, 'type') as FormControl;
  }

  protected getIdentifier(i: number): FormControl {
    return this.getControl(i, 'identifier') as FormControl;
  }

  protected getControl(i: number, controlName: string): FormControl {
    // Dynamically get the control at index `i` with the specified controlName
    return this.customerReferenceList.at(i).get(controlName) as FormControl;
  }
  //#endregion

  //#region List add/remove
  protected addNewLine(item?: ExternalReference | null): void {
    if (item == null || !item) {
      item = initialExternalReference();
      item.type = 'CRR'
      this.wagonInformation.externalReferences.push(item)
    }

    // Define form group with controls, setting "type" to "CRR" and making it read-only if no reference is provided
    const itemGroup: FormGroup = this.fb.group({
      type: new FormControl({ value: item ? item.type : 'CRR', disabled: true }),  // Always "CRR" and read-only
      identifier: new FormControl(item ? item.identifier : ''),  // Initialize identifier field
    });

    // Add this form group to the form array
    this.customerReferenceList.push(itemGroup);
  }

  protected removeLine(idx: number): void {
    if (this.customerReferenceList.length > 1) {
      this.customerReferenceList.removeAt(idx);
      this.wagonInformation.externalReferences.splice(idx, 1);
      this.wagonDataCommunicationService.changeWagonInformation(this.wagonInformation, this.constructor.name);
    }
  }
  //#endregion 
}