import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { Authorization, CustomerData, CustomerProfile } from 'src/app/trainorder/models/authorization';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private storage: Storage;
  private activeProfilesKey: string = "activeProfiles";
  private immediateAuthorizationsKey: string = "immediateAuthorizations";
  private customerAllAuthorizationsKey: string = "customerAllAuthorizations";
  private customerProfilesKey: string = "customerProfiles";
  private usernameKey: string = "username";
  private isTMUserKey: string = "isTMUserKey";
  
  constructor() {
    this.storage = localStorage;
  }

  public clearStorage() {
    localStorage.clear();
    sessionStorage.clear();
  }

  clearAuthorizationStorage() {
    this.storage.removeItem(this.activeProfilesKey);
    this.storage.removeItem(this.immediateAuthorizationsKey);
    this.storage.removeItem(this.customerProfilesKey);
    this.storage.removeItem(this.usernameKey);
  }

  removeActiveProfiles() {
    this.storage.removeItem(this.activeProfilesKey);
  }

  private storageSubj = new Subject<any>();
  private authorizationStorageSubj = new Subject<any>();
  private authorizationStorageBehaviourSubj: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  watchPermissions() {
    return this.authorizationStorageSubj.asObservable();
  }

  public getAndWatchPermissions(): BehaviorSubject<boolean> {
    return this.authorizationStorageBehaviourSubj;
  }

  setImmediateAuthorizations(value: Authorization[], triggerLoaded: boolean = false) {
    this.storage.setItem(this.immediateAuthorizationsKey, JSON.stringify(value));
    if(triggerLoaded) {
      this.authorizationStorageSubj.next(true);
    }
    this.authorizationStorageBehaviourSubj.next(true);
  }

  getImmediateAuthorizations(): Authorization[] | null {
    return this.toArray(this.storage.getItem(this.immediateAuthorizationsKey));
  }

  setCustomerAllAuthorizations(value: Authorization[]) {
    this.storage.setItem(this.customerAllAuthorizationsKey, JSON.stringify(value));
 
  }

  getcustomerAllAuthorizations(): Authorization[] | null {
    return this.toArray(this.storage.getItem(this.customerAllAuthorizationsKey));
  }

  setCustomerProfiles(value: CustomerData[], triggerLoaded: boolean = false) {
    this.storage.setItem(this.customerProfilesKey, JSON.stringify(value));
    if(triggerLoaded) {
      this.authorizationStorageSubj.next(true);
    }
    this.authorizationStorageBehaviourSubj.next(true);
  }

  getCustomerProfiles(): CustomerData[] | null {
    return this.toArray(this.storage.getItem(this.customerProfilesKey));
  }

  public setIsTMUser(isTM: boolean): void {
    this.storage.setItem(this.isTMUserKey, JSON.stringify(isTM));
  }

  public getIsTMUser(): boolean | null {
    const result = this.storage.getItem(this.isTMUserKey);
    if(result && result == "true") {
      return true;
    }
    return false;
  }

  setActiveProfiles(activeProfiles: CustomerData[], triggerLoaded: boolean = false) {
    this.storage.setItem(this.activeProfilesKey, JSON.stringify(activeProfiles));
    if(triggerLoaded) {
      this.authorizationStorageSubj.next(true);
    }
    this.authorizationStorageBehaviourSubj.next(true);
  }

  getActiveProfiles(): CustomerData[] | null {
    return this.toArray(this.storage.getItem(this.activeProfilesKey));
  }

  getActiveSgvAndPartnerIdList(): CustomerProfile[] | null | undefined {
    if(this.getActiveProfiles() == null) return null;
    return this.getActiveProfiles()?.map( cd => {
      return {sgvId: cd.sgvId, partnerId: cd.partnerId};
    });
  }

  setUsername(value: string) {
    this.storage.setItem(this.usernameKey, value);
    this.storageSubj.next(true);
  }

  getUsername() {
    return this.storage.getItem(this.usernameKey);
  }

  watchLocalStorage(): Observable<any> {
    return this.storageSubj.asObservable();
  }

  setLocalStorage(key: string, value: string) {
    this.storage.setItem(key, value);
    this.storageSubj.next(true);
  }

  getItem(key: string): string | null {
    if (this.storage.getItem(key))
      return this.storage.getItem(key);
    return null;
  }

  private toArray(str: string | null): [] | null {
    if(!str || str == null) return null;

    const jsonObj = JSON.parse(str);
    const obj: [] = Object.setPrototypeOf(jsonObj, []);
    return obj;
  }
}
