import { ChangeDetectorRef, Component, Injector, Input, OnInit, } from '@angular/core';
import { SectionName } from '../../enums/order-enums';
import { Goods, initalWagonInformation, initialEmptyWagonInformation, initialGood, initialRailOrder, RailOrder, WagonInformation } from '../../models/api-railorder';
import { NewOrderMainComponent } from '../../new-order-main/new-order-main.component';
import { SectionBase } from '../section.base';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingStatusOption } from '../../models/api-loading-status';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { ApiGoodResponse, GoodModel } from 'src/app/trainorder/models/Cargo.model';
import { NewOrderWagonDetailDialogComponent } from './new-order-wagon-detail-dialog/new-order-wagon-detail-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { TrainorderService } from 'src/app/trainorder/services/trainorder.service';
import { NewOrderWagonDetailDialogService } from './service/new-order-wagon-detail-dialog.service';

@Component({
  selector: 'app-new-order-wagon-data',
  templateUrl: './new-order-wagon-data.component.html',
  styleUrls: ['../../new-order-main/new-order-main.component.scss',
    './new-order-wagon-data.component.scss']
})
export class NewOrderWagonDataComponent extends SectionBase implements OnInit {
  @Input() currentSectionName: SectionName;



  //#region Declarations NHM-Code Autocomplete
  private subscriptionNhmCodeAutoComplete: Subscription = new Subscription();
  private nhmCodeInputChange: Subject<string> = new Subject<string>(); // | to debounce input requests
  protected nhmCodeAutocomplete: GoodModel[] = [];
  //#endregion
  //#region Protected

  // Define loading status options for dropdown
  protected loadingStatusOptions: LoadingStatusOption[] = [
    { value: true, label: 'Shared.Loading-state-loaded' },
    { value: false, label: 'Shared.Loading-state-empty' }
  ];

  protected formGroup: FormGroup;
  protected SectionName = SectionName;
  protected parent: NewOrderMainComponent;
  protected numbersOfWagonsList: number[] = [];
  protected railOrder: RailOrder;
  protected isAnyWagonSelected = false;  // Flag to track if any wagon is selected
  protected totalWeight: number = 0;
  protected weightChangeSubscriptions: Subscription[] = [];
  protected checkboxWagonSubscriptions: Subscription[] = [];
  //#endregion
  private wagonDetailDialogRef: MatDialogRef<NewOrderWagonDetailDialogComponent> = null;
  // private wagonInformation: WagonInformation = null;

  constructor(private injector: Injector,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private newOrderWagonDetailService: NewOrderWagonDetailDialogService,
    private trainorderService: TrainorderService) {
    super();

    this.parent = this.injector.get<NewOrderMainComponent>(NewOrderMainComponent);

  }

  public ngOnInit(): void {
    this.createForm();
    this.loadLists();
    this.subscriptionNhmCodeAutoComplete.add(this.nhmCodeInputChange.pipe(debounceTime(500)).subscribe((input) => {
      this.getNhmCodeAutocomplete(input);
    }));

    this.subscribeToNumberOfWagons();

  }

  //#region Getters
  // Get the FormArray for wagon information list
  protected get wagonInformationList(): FormArray {
    return this.formGroup?.get('wagonInformationList') as FormArray || this.fb.array([]);
  }

  // Getters for specific form controls within the FormGroup
  protected getWagonNumber(index: number): FormControl {
    return this.wagonInformationList.at(index).get('wagonNumber') as FormControl;
  }

  protected getFirstGoodWeight(index: number): FormControl {
    return this.wagonInformationList.at(index).get('firstGoodWeight') as FormControl;
  }

  protected getTypeOfWagon(index: number): FormControl {
    return this.wagonInformationList.at(index).get('typeOfWagon') as FormControl;
  }

  protected getfirstGoodAdditionalDeclaration(index: number): FormControl {
    return this.wagonInformationList.at(index).get('firstGoodAdditionalDeclaration') as FormControl;
  }

  protected getFirstGoodNhmCode(index: number): FormControl {
    return this.wagonInformationList.at(index).get('firstGoodNhmCode') as FormControl;
  }

  protected getLoadingStatus(index: number): FormControl {
    return this.wagonInformationList.at(index).get('loadingStatus') as FormControl;
  }

  protected getCheckboxWagon(index: number): FormControl {
    return this.wagonInformationList.at(index).get('checkboxWagon') as FormControl;
  }
  //#endregion
  //#region setRailOrder
  public updateRailOrder(ro: RailOrder) {
    console.log("WagonData ", ro);
    this.railOrder = ro;
    this.setFormValues();
    this.cd.detectChanges();
  }
  //#endregion

  private createForm(): void {
    this.formGroup = this.fb.group({
      numberOfWagons: ['1'],
      wagonInformationList: this.fb.array([])
    });
  }

  private setFormValues(): void {
    if (this.railOrder) {
      if (this.railOrder?.wagonInformation?.length == 0) {
        this.railOrder?.wagonInformation?.push(initalWagonInformation());
      }

      this.updateWagonInformationList(this.railOrder.wagonInformation);

      // Set the number of wagons
      this.formGroup.patchValue({
        numberOfWagons: this.railOrder.numberOfWagons || '1'
      });
    }

  }

  private updateWagonInformationList(wagonInformationList: any[]): void {
    const wagonArray = this.formGroup.get('wagonInformationList') as FormArray;

    // Clear the existing array
    wagonArray.clear();

    // Add new items to the array based on the provided data
    wagonInformationList.forEach(wagon => {
      wagonArray.push(this.fb.group({
        wagonNumber: [wagon?.wagonNumber || ''],
        typeOfWagon: [wagon?.typeOfWagon || ''],
        loadingStatus: [wagon?.loadingStatus || ''],
        checkboxWagon: false,
        firstGoodWeight: [wagon?.goods[0]?.weight || ''],
        firstGoodAdditionalDeclaration: [wagon?.goods[0]?.additionalDeclaration || ''],
        firstGoodNhmCode: [wagon?.goods[0]?.weight || '']
        // Add other fields as necessary
      }));
    });
  }

  protected get numberOfWagons(): FormControl {
    return this.formGroup.get('numberOfWagons') as FormControl;
  }
  protected set numberOfWagons(value: number) {
    if (this.formGroup.get('numberOfWagons')) {
      this.formGroup.get('numberOfWagons')?.setValue(value);
    }
  }
  //#endregion

  private loadLists() {
    for (let i = 1; i <= 99; i++) {
      this.numbersOfWagonsList.push(i);
    }
  }

  onNumberOfWagonsChanged(newNumber: number): void {
    const numberOfWagonsControl = this.formGroup.get('numberOfWagons');
    if (numberOfWagonsControl) {
      numberOfWagonsControl.setValue(newNumber);
    }
  }

  protected validate(): string[] {
    console.log('validate Wagon information section');
    return [];
  }

  //#region Autocompleter

  /**
     * Emits the next input value from the field
     * @param event
     * @param field type of the field
     */
  protected autocompleteInputChanged(event: any, field: string): void {
    switch (field) {
      case 'nhm-code':
        this.nhmCodeInputChange.next(event.target.value);
        break;
      default:
        break;
    }
  }

  protected trackByFn(index: any, item: any): any {
    return index;
  }

  getNhmCodeAutocomplete(input: any): void {
    if (input.length >= 3 && !this.nhmCodeAutocomplete.find((elem) => elem.nhmCode === input)) {
      this.trainorderService.getCargoInfo(input).then((result: ApiGoodResponse) => {
        // Take only 30 answers that fit (array may be 1000+ in length), otherwise it takes a lot of resources to build these elements
        this.nhmCodeAutocomplete = result.slice(0, 30).sort((a, b) => (a.nhmCode > b.nhmCode ? 1 : -1));
      });
    }
  }
  //#endregion

  //#region weight 
  // Calculate total weight based on current values
  protected calculateTotalWeight() {
    this.totalWeight = this.wagonInformationList.controls.reduce((sum, group) => {
      const weight = group.get('firstGoodWeight')?.value || 0;
      return sum + parseFloat(weight);
    }, 0);
  }

  // Add subscription for the weight control of the newly added item
  protected addWeightSubscription(weightControl: FormControl): void {
    if (weightControl) {
      const sub = weightControl.valueChanges.subscribe(() => {
        this.calculateTotalWeight();
      });
      this.weightChangeSubscriptions.push(sub);
    }
  }

  protected addCheckboxWagonSubscription(checkboxWagon: FormControl): void {
    if (checkboxWagon) {
      const sub = checkboxWagon.valueChanges.subscribe(() => {
        this.isWagonSelected();
      });
      this.checkboxWagonSubscriptions.push(sub);
    }
  }

  protected formatToTwoDecimals(index: number, controlName: string): void {
    const control = this.wagonInformationList.at(index).get(controlName) as FormControl;
    if (control && control.value) {
      // Überprüfe, ob der Wert eine Zahl ist
      const value = parseFloat(control.value);
      if (!isNaN(value)) {
        // Rundet auf 2 Dezimalstellen
        control.setValue(value.toFixed(2));
      }
    }
  }
  //#endregion

  //#region WagonCount

  // Subscribe to changes in numberOfWagons and dynamically adjust the wagon list
  private subscribeToNumberOfWagons(): void {
    const numberOfWagonsControl = this.formGroup.get('numberOfWagons') as FormControl;

    if (numberOfWagonsControl) {
      numberOfWagonsControl.valueChanges.subscribe((numberOfWagons: number) => {
        this.adjustWagonList(numberOfWagons);
      });
    }
  }

  // Adjust the wagon information list based on the numberOfWagons
  private adjustWagonList(numberOfWagons: number): void {
    const wagonInformationList = this.formGroup.get('wagonInformationList') as FormArray;

    // Retain existing values and remove any excess wagons
    const currentWagonCount = wagonInformationList.length;

    if (numberOfWagons > currentWagonCount) {
      // If we need to add more wagons
      for (let i = currentWagonCount; i < numberOfWagons; i++) {
        wagonInformationList.push(this.createWagonFormGroup());
        this.railOrder.wagonInformation.push(initalWagonInformation());
      }
    } else if (numberOfWagons < currentWagonCount) {
      // If we need to remove wagons, remove from the end
      while (wagonInformationList.length > numberOfWagons) {
        wagonInformationList.removeAt(wagonInformationList.length - 1);
      }
    }
    //this.numberOfWagonsChanged.emit(this.wagonInformationList.length);
  }

  // Create a new FormGroup for each wagon
  private createWagonFormGroup(): FormGroup {
    const wagonInfo = initalWagonInformation();
    wagonInfo.loadingStatus = false;
    const goodInfo = initialGood();
    wagonInfo.goods.push(goodInfo);

    this.railOrder.wagonInformation.push(wagonInfo)
    const itemGroup: FormGroup = this.fb.group({
      checkboxWagon: new FormControl(''),
      wagonNumber: new FormControl(wagonInfo.wagonNumber),
      loadingStatus: new FormControl(wagonInfo.loadingStatus),
      typeOfWagon: new FormControl(wagonInfo.typeOfWagon),
      firstGoodWeight: new FormControl(wagonInfo?.goods.at(0)?.weight),
      firstGoodAdditionalDeclaration: new FormControl(wagonInfo?.goods.at(0)?.additionalDeclaration),
      firstGoodNhmCode: new FormControl(wagonInfo?.goods.at(0)?.nhmCode)
    });

    const firstGoodWeightControl = itemGroup.get('firstGoodWeight') as FormControl;
    this.addWeightSubscription(firstGoodWeightControl);
    const checkboxWagonControl = itemGroup.get('checkboxWagon') as FormControl;
    this.addCheckboxWagonSubscription(checkboxWagonControl);
    return itemGroup;
  }
  //#endregion


  //#region FormButton
  protected openEditWindow(idx: number) {
    this.wagonDetailDialogRef = this.newOrderWagonDetailService.openWagonDetailDialog(this.railOrder, idx);
    this.wagonDetailDialogRef.afterClosed().subscribe({
      next: (result) => {
        console.log("result", result);
        console.log("this.railOrder.wagonInformation[idx]", this.railOrder.wagonInformation[idx]);
        if (result != null) {
          this.railOrder.wagonInformation[idx] = result;
        }
        console.log("this.railOrder.wagonInformation[idx]", this.railOrder.wagonInformation[idx]);
      }
    });
  }

  protected addNewLine(item?: WagonInformation): void {
    
    const itemGroup: FormGroup = this.createWagonFormGroup(); 

    this.wagonInformationList.push(itemGroup);
    const firstGoodWeightControl = itemGroup.get('firstGoodWeight') as FormControl;
    this.addWeightSubscription(firstGoodWeightControl);

    const checkboxWagonControl = itemGroup.get('checkboxWagon') as FormControl;
    this.addCheckboxWagonSubscription(checkboxWagonControl);

    // Emit the updated number of wagons
    this.numberOfWagons = this.wagonInformationList.length;

    // Update railOrder wagon information
    let newWagon: WagonInformation = initalWagonInformation();
    newWagon = this.buildWagonInformation(itemGroup);
    this.railOrder.wagonInformation.push(newWagon);
  }

  // Check if any wagon is selected
  protected isWagonSelected(): void {
    this.isAnyWagonSelected = this.wagonInformationList.controls.some(control => control.get('checkboxWagon')?.value);
  }



  // Helper to build WagonInformation from FormGroup
  private buildWagonInformation(formGroup: FormGroup): WagonInformation {
    
    const wagon: WagonInformation = initalWagonInformation();
    wagon.wagonNumber= formGroup.get('wagonNumber')?.value;
    wagon.loadingStatus= formGroup.get('loadingStatus')?.value;
    wagon.typeOfWagon= formGroup.get('typeOfWagon')?.value;
    
    const good: Goods = initialGood()
      good.weight = formGroup.get('firstGoodWeight')?.value;
      good.additionalDescription= formGroup.get('firstGoodAdditionalDeclaration')?.value;
      good.nhmCode= formGroup.get('firstGoodNhmCode')?.value;
      wagon.goods.push(good);
    return wagon;
  }

  private buildWagonInformationFromForm(): WagonInformation[] {
    this.wagonInformationList.controls.forEach((group: FormGroup, index: number) => {
      // Access the existing wagon directly by index
      const existingWagon = this.railOrder.wagonInformation[index];

      // Update wagonNumber if changed
      const wagonNumber = group.get('wagonNumber')?.value;
      if (wagonNumber !== existingWagon.wagonNumber) {
        existingWagon.wagonNumber = wagonNumber;
      }

      // Update loadingStatus if changed
      const loadingStatus = group.get('loadingStatus')?.value;
      if (loadingStatus !== existingWagon.loadingStatus) {
        existingWagon.loadingStatus = loadingStatus;
      }

      // Update typeOfWagon if changed
      const typeOfWagon = group.get('typeOfWagon')?.value;
      if (typeOfWagon !== existingWagon.typeOfWagon) {
        existingWagon.typeOfWagon = typeOfWagon;
      }

      // Access the first good from the existing wagon and form group
      const existingGood = existingWagon.goods?.[0] || initialGood();
      const firstGoodWeight = group.get('firstGoodWeight')?.value;
      const firstGoodAdditionalDeclaration = group.get('firstGoodAdditionalDeclaration')?.value;
      const firstGoodNhmCode = group.get('firstGoodNhmCode')?.value;

      // Update weight if changed
      if (firstGoodWeight !== existingGood.weight) {
        existingGood.weight = firstGoodWeight;
      }

      // Update additionalDescription if changed
      if (firstGoodAdditionalDeclaration !== existingGood.additionalDescription) {
        existingGood.additionalDescription = firstGoodAdditionalDeclaration;
      }

      // Update nhmCode if changed
      if (firstGoodNhmCode !== existingGood.nhmCode) {
        existingGood.nhmCode = firstGoodNhmCode;
      }

      // If goods array doesn't exist, create it and push the updated good
      if (!existingWagon.goods || !existingWagon.goods.length) {
        existingWagon.goods = [existingGood];
      }

      // If the first good has been updated, ensure it's at the first position
      else if (existingWagon.goods[0] !== existingGood) {
        existingWagon.goods[0] = existingGood;
      }

      // No need to push the updated wagon to the array, just modifying it directly will suffice
      // Since we are modifying the objects directly, this step is not necessary
      this.railOrder.wagonInformation[index] = existingWagon;
    });

    // Return the updated wagonInformation list for reference
    return this.railOrder.wagonInformation;
  }



  // Method to remove selected wagons
  protected removeSelectedWagons(): void {
    const wagonInformationList = this.formGroup.get('wagonInformationList') as FormArray;

    // Create an array to hold the indices of the wagons to be removed
    const indicesToRemove: number[] = [];

    // Check for which wagons are selected
    wagonInformationList.controls.forEach((control, index) => {
      if (control.get('checkboxWagon')?.value) {
        indicesToRemove.push(index);
      }
    });

    // Remove wagons from both the form array and railOrder.wagonInformation array
    for (let i = indicesToRemove.length - 1; i >= 0; i--) {
      // Remove from form array
      if (wagonInformationList.length > 1) {
        wagonInformationList.removeAt(indicesToRemove[i]);
        // Remove from railOrder wagon information array
        this.railOrder.wagonInformation.splice(indicesToRemove[i], 1);
      }
    }

    // Emit the updated number of wagons after removal
    this.numberOfWagons = wagonInformationList.length
    this.numberOfWagons = this.wagonInformationList.length;
  }

  protected removeLine(idx: number): void {
    if (this.wagonInformationList.length > 1) {
      this.wagonInformationList.removeAt(idx);
      // Also remove the wagon information from railOrder
      this.railOrder.wagonInformation.splice(idx, 1);
    }

    this.calculateTotalWeight();
    this.numberOfWagons = this.wagonInformationList.length;
  }
  //#endregion
}