import { ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SectionBase } from '../section.base';
import { SectionName } from '../../enums/order-enums';
import { AttachedDocument, RailOrder } from '../../models/api-railorder';
import { KeyValuePair, RAILORDER_CONSIGNOR_DECLARATION_CODES_LISTNAME } from '../../models/api-dynamic-storage';
import { DynamicStorageService } from '../../service/dynamic-session-storage.service';
import { NewOrderMainComponent } from '../../new-order-main/new-order-main.component';

@Component({
  selector: 'app-new-order-sender-policy',
  templateUrl: './new-order-sender-policy.component.html',
  styleUrls: ['../../new-order-main/new-order-main.component.scss',
    './new-order-sender-policy.component.scss']
})

export class NewOrderSenderPolicyComponent extends SectionBase implements OnInit {
  @Input() currentSectionName: SectionName;
  
  protected railOrder: RailOrder;
  protected isAttachedDocumentsGroupVisible: boolean = false;
  protected isTakeoverConditionsSeaFreightGroupVisible: boolean = false;
  protected SectionName = SectionName;
  protected parent: NewOrderMainComponent;
  protected formGroup: FormGroup;

  // List of options for:  ConsignorDeclarationCodes
  protected consignorDeclarationCodeOptions: KeyValuePair[] = [];
  protected attachedDocumentCodeList: KeyValuePair[] = [];
  protected takeOverconditionList: KeyValuePair[] = [];

  constructor(private injector: Injector,
    private dynamicStorageService: DynamicStorageService,
    private formBuilder: FormBuilder,
    private cd : ChangeDetectorRef
  ) {
    super();
    this.parent = this.injector.get<NewOrderMainComponent>(NewOrderMainComponent);
    this.createForm();
  }

  ngOnInit(): void {
    this.loadRailorderConsignorDeclarationCodes();  // Lade die Dropdown-Werte beim Initialisieren
    if (this.consignorDeclarations?.length === 0) {
      this.addConsignorDeclaration();
    }
    if (this.attachedDocuments?.length === 0) {
      this.addAttachedDocument();
    }
  }

  public updateRailOrder(ro: RailOrder) {
    console.log(ro);
    this.railOrder = ro;
    this.setFormValues();
    this.cd.detectChanges();
  }

  private subscribeToFormChanges(): void {
    // Subscribe to form changes only triggered by user input
    this.formGroup.valueChanges.subscribe((changes) => {
      // Check if the form is touched or dirty to ensure that only user input triggers the update
      if (this.formGroup.dirty || this.formGroup.touched) {
        this.updateRailOrderFromForm(changes);
      }
    });
  }

  private updateRailOrderFromForm(changes: any): void {
   
  
    // Update external references (sender and receiver)
    const senderReference = this.railOrder.externalReferences?.find(ref => ref.type === 'RAR');
    if (senderReference) {
      senderReference.identifier = changes.externalReferenceSender;
    } else if (changes.externalReferenceSender) {
      this.railOrder.externalReferences.push({ type: 'RAR', identifier: changes.externalReferenceSender });
    }
  
    const receiverReference = this.railOrder.externalReferences?.find(ref => ref.type === 'RER');
    if (receiverReference) {
      receiverReference.identifier = changes.externalReferenceReceiver;
    } else if (changes.externalReferenceReceiver) {
      this.railOrder.externalReferences.push({ type: 'RER', identifier: changes.externalReferenceReceiver });
    }
  
    // Update special annotations
    if (!this.railOrder.specialAnnotations) {
      this.railOrder.specialAnnotations = {};
    }
    this.railOrder.specialAnnotations.annotationOfConsignorDescription = changes.annotationOfConsignorDescription;
    this.railOrder.specialAnnotations.additionalDeclarationOfCarrier = changes.additionalDeclarationOfCarrier;
  
    // Update consignor declarations
    this.railOrder.specialAnnotations.consignorDeclarations = changes.consignorDeclarations?.map((declaration: any) => ({
      code: declaration.consignorDeclarationCode,
      description: declaration.consignorDeclarationDescription,
      additionalInformation: declaration.consignorDeclarationAdditionalInformation
    }));
  
    // Update attached documents
    this.railOrder.attachedDocuments = changes.attachedDocuments?.map((document: any) => ({
      code: document.attachedDocumentCode,
      description: document.attachedDocumentDescription,
      dateOfIssue: document.attachedDocumentDateOfIssue,
      numberOfOriginals: document.attachedDocumentNumberOfOriginals,
      referenceNumber: document.attachedDocumentReferenceNumber
    }));
  
    // Update takeover conditions
    if (!this.railOrder.handOverConditions) {
      this.railOrder.handOverConditions = {};
    }
    this.railOrder.handOverConditions.typeOfTakeover = changes.takeoverConditionsTypeOfTakeover;
    this.railOrder.handOverConditions.shipOwner = changes.takeoverConditionsShipOwner;
    this.railOrder.handOverConditions.shipName = changes.takeoverConditionsShipName;
    this.railOrder.handOverConditions.originPort = changes.takeoverConditionsOriginPort;
  
    if (!this.railOrder.handOverConditions.arrival) {
      this.railOrder.handOverConditions.arrival = null;
    }
    
    if (changes.takeoverConditionsAarrivalDate) {
      const newDate = new Date(changes.takeoverConditionsAarrivalDate);
  
      // If there's no existing arrival time, set it to 00:00
      if (!this.railOrder.handOverConditions.arrival) {
        newDate.setHours(0, 0); // Set to 00:00 if no arrival
      } else {
        // Retain the existing time if arrival already exists
        const existingArrival = this.railOrder.handOverConditions.arrival;
        newDate.setHours(existingArrival.getHours(), existingArrival.getMinutes());
      }
      this.railOrder.handOverConditions.arrival = newDate; // Update the arrival date
    }
  
    // Check if the arrival time is being updated
    if (changes.takeoverConditionsArrivalTime) {
      const timeParts = changes.takeoverConditionsArrivalTime.split(':').map(Number);
      const existingArrival = this.railOrder.handOverConditions.arrival;
  
      if (existingArrival) {
        const hours = timeParts[0] || 0; // Set hours or default to 0
        const minutes = timeParts[1] || 0; // Set minutes or default to 0
  
        existingArrival.setHours(hours, minutes); // Update existing arrival time
        this.railOrder.handOverConditions.arrival = existingArrival; // Update the arrival with new time
      } else {
        // If there's no existing arrival, set it to the provided time
        this.railOrder.handOverConditions.arrival = new Date(); // Create a new date
        this.railOrder.handOverConditions.arrival.setHours(timeParts[0] || 0, timeParts[1] || 0); // Set to provided time
      }
    }
    // 
    console.log('Sender / Policy: Updated RailOrder:', this.railOrder);
    // Trigger change detection to ensure that the changes are reflected in the view
    this.cd.detectChanges();
  }

  createForm() {
    this.formGroup = this.formBuilder.group({

      externalReferenceSender: new FormControl(''),
      externalReferenceReceiver: new FormControl(''),

      annotationOfConsignorDescription: new FormControl('', [
        Validators.maxLength(350)
      ]),
      additionalDeclarationOfCarrier: new FormControl('', [
        Validators.maxLength(350)
      ]),

      consignorDeclarations: this.formBuilder.array([]),
      attachedDocuments: this.formBuilder.array([]),


      takeoverConditionsTypeOfTakeover: new FormControl('', [
        Validators.max(9)
      ]),
      takeoverConditionsShipOwner: new FormControl('', [
        Validators.maxLength(35)
      ]),
      takeoverConditionsShipName: new FormControl('', [
        Validators.maxLength(35)
      ]),
      takeoverConditionsOriginPort: new FormControl('', [
        Validators.maxLength(35)
      ]),
      takeoverConditionsAarrivalDate: new FormControl(''),
      takeoverConditionsArrivalTime: new FormControl(''),

    });
    this.subscribeToFormChanges();
  }

  protected toggleAttachedDocumentsGroupVisibility() {
    this.isAttachedDocumentsGroupVisible = !this.isAttachedDocumentsGroupVisible;
  }

  protected toggleTakeoverConditionsSeaFreightGroupVisibiliy() {
    this.isTakeoverConditionsSeaFreightGroupVisible = !this.isTakeoverConditionsSeaFreightGroupVisible;
  }

  private setFormValues() {
    this.setExternalReferenceSender();
    this.setExternalReferenceReceiver();
    this.setFormValuesSpecialAnnotations();
    this.setAttachedDocuments(this.railOrder.attachedDocuments);
    this.setFormValuesTakeoverConditions();
  };

  private setFormValuesSpecialAnnotations(): void {
    this.annotationOfConsignorDescription?.setValue(this.railOrder?.specialAnnotations?.annotationOfConsignorDescription);
    this.additionalDeclarationOfCarrier?.setValue(this.railOrder?.specialAnnotations?.additionalDeclarationOfCarrier);
  }

  private setFormValuesTakeoverConditions(): void {
    this.takeoverConditionsAarrivalDate.setValue(this.railOrder?.handOverConditions?.arrival?.getDate());
    this.takeoverConditionsArrivalTime.setValue(this.railOrder?.handOverConditions?.arrival?.getTime());
    this.takeoverConditionsOriginPort.setValue(this.railOrder?.handOverConditions?.originPort);
    this.takeoverConditionsShipName.setValue(this.railOrder?.handOverConditions?.shipName);
    this.takeoverConditionsShipOwner.setValue(this.railOrder?.handOverConditions?.shipOwner);
    this.takeoverConditionsTypeOfTakeover.setValue(this.railOrder?.handOverConditions?.typeOfTakeover);
  }

  public onChangeOrder(): void {
    // Handle changes to the order
  }

  // Section Consignor declaration
  private loadRailorderConsignorDeclarationCodes(): void {
    this.dynamicStorageService.retrieveKeyValueList(RAILORDER_CONSIGNOR_DECLARATION_CODES_LISTNAME).subscribe(
      (data: KeyValuePair[]) => {
        this.consignorDeclarationCodeOptions = data;
      },
      (error) => {
        console.error('Error laoding RailorderConsignorDeclarationCodes', error);
      }
    );
  }

  // Attached-documents
  // Set form values for attached documents
  private setAttachedDocuments(attachedDocuments: AttachedDocument[]) {
    this.attachedDocuments?.clear(); // Clear current documents
    attachedDocuments?.forEach(doc => {
      this.attachedDocuments?.push(this.createAttachedDocument(doc));
    });
  }

  private setExternalReferenceSender() {
    this.externalReferenceSender?.setValue(null);
    const senderReference = this.railOrder.externalReferences.find(ref => ref.type === 'RAR');
    this.externalReferenceSender?.setValue(senderReference);
  }

  private setExternalReferenceReceiver() {
    this.externalReferenceReceiver?.setValue(null);
    const receiverReference = this.railOrder?.externalReferences?.find(ref => ref.type === 'RER');
    this.externalReferenceReceiver?.setValue(receiverReference);
  }

  // Method to create a new attached document form group
  createAttachedDocument(document?: AttachedDocument): FormGroup {
    return this.formBuilder.group({
      attachedDocumentCode: new FormControl(document?.code || '', [Validators.required, Validators.maxLength(3)]),
      attachedDocumentDescription: new FormControl(document?.description || '', [Validators.maxLength(70)]),
      attachedDocumentDateOfIssue: new FormControl(document?.dateOfIssue || '', [Validators.required]), // Date format validation
      attachedDocumentNumberOfOriginals: new FormControl(document?.numberOfOriginals || '', [Validators.min(0), Validators.max(99)]),
      attachedDocumentReferenceNumber: new FormControl(document?.referenceNumber || '', [Validators.maxLength(35)])
    });
  }

  // Method to add a new attached document
  addAttachedDocument(): void {
    if (this.attachedDocuments?.length < 10) {
      this.attachedDocuments?.push(this.createAttachedDocument());
    } else {
      console.log('Only 10 attached documents are allowed.');
    }
  }

  // Method to remove an attached document by index
  removeAttachedDocument(attachedDocumentAtIndex: number): void {
    if (this.attachedDocuments.length > 0) {
      this.attachedDocuments.removeAt(attachedDocumentAtIndex);
    }
  }

  // Mapping the form data back to the RailOrder model
  public mapAttachedDocumentsToRailOrder(): void {
    const attachedDocuments: AttachedDocument[] = this.attachedDocuments?.value.map((doc: AttachedDocument) => ({
      attachedDocumentCode: doc.code,
      attachedDocumentDescription: doc.description,
      attachedDocumentDateOfIssue: doc.dateOfIssue,
      attachedDocumentNumberOfOriginals: doc.numberOfOriginals,
      attachedDocumentReferenceNumber: doc.referenceNumber
    }));

    this.railOrder.attachedDocuments = attachedDocuments;
  }

  // Getter for attached documents form array
  public get attachedDocuments(): FormArray {
    return this.formGroup.get('attachedDocuments') as FormArray;
  }

  // Consignor-declaration
  public get consignorDeclarations(): FormArray {
    return this.formGroup.get('consignorDeclarations') as FormArray;
  }

  public get annotationOfConsignorDescription(): FormControl {
    return this.formGroup.get('annotationOfConsignorDescription') as FormControl;
  }

  public get additionalDeclarationOfCarrier(): FormControl {
    return this.formGroup.get('additionalDeclarationOfCarrier') as FormControl;
  }

  // Method to create a new  consignorDeclaration
  createConsignorDeclaration(): FormGroup {
    return this.formBuilder.group({
      consignorDeclarationCode: new FormControl('', [Validators.required]),  // Code ist erforderlich
      consignorDeclarationDescription: new FormControl('', [Validators.maxLength(100)]),
      consignorDeclarationAdditionalInformation: new FormControl('', [Validators.maxLength(350)])
    });
  }

  // Method to add new consignor declaration
  addConsignorDeclaration(): void {
    if (this.consignorDeclarations?.length < 5) {
      this.consignorDeclarations?.push(this.createConsignorDeclaration());
    } else {
      console.log('Only 5 consignor declarations are allowed.');
    }
  }

  // Method to remove a consignor declaration by index
  removeConsignorDeclaration(index: number): void {
    if (this.consignorDeclarations?.length > 1) {
      this.consignorDeclarations?.removeAt(index);
    }
  }

  // TakeoverConditions
  public get takeoverConditionsTypeOfTakeover(): FormControl {
    return this.formGroup.get('takeoverConditionsTypeOfTakeover') as FormControl;
  }
  public get takeoverConditionsShipOwner(): FormControl {
    return this.formGroup.get('takeoverConditionsShipOwner') as FormControl;
  }
  public get takeoverConditionsShipName(): FormControl {
    return this.formGroup.get('takeoverConditionsShipName') as FormControl;
  }
  public get takeoverConditionsOriginPort(): FormControl {
    return this.formGroup.get('takeoverConditionsOriginPort') as FormControl;
  }
  public get takeoverConditionsAarrivalDate(): FormControl {
    return this.formGroup.get('takeoverConditionsAarrivalDate') as FormControl;
  }
  public get takeoverConditionsArrivalTime(): FormControl {
    return this.formGroup.get('takeoverConditionsArrivalTime') as FormControl;
  }

  public get externalReferenceSender(): FormControl {
    return this.formGroup.get('externalReferencSender') as FormControl;
  }

  public get externalReferenceReceiver(): FormControl {
    return this.formGroup.get('externalReferencReceiver') as FormControl;
  }

  // Validation
  public validate(): string[] {
    const errors: string[] = [];

    // Validate 'annotationOfConsignorDescription'
    if (this.annotationOfConsignorDescription?.errors) {
      if (this.annotationOfConsignorDescription?.errors['maxlength']) {
        errors.push('Annotation of consignor description exceeds the maximum length of 350 characters.');
      }
    }

    // Validate 'additionalDeclarationOfCarrier'
    if (this.additionalDeclarationOfCarrier?.errors) {
      if (this.additionalDeclarationOfCarrier?.errors['maxlength']) {
        errors.push('Additional declaration of carrier exceeds the maximum length of 350 characters.');
      }
    }

    // Log validation result and return errors
    if (errors.length > 0) {
      console.log('Validation errors:', errors);
    } else {
      console.log('Validation passed.');
    }

    return errors;
  }

  protected onChangeConsignorDeclarationDescription($event: Event) {
    throw new Error('Method not implemented.');
  }
  protected onChangeConsignorDeclarationCode($event: Event) {
    throw new Error('Method not implemented.');
  }
  protected onChangeAttachedDocumentDateOfIssue($event: Event) {
    throw new Error('Method not implemented.');
  }
  protected onChangeAttachedDocumentDescription($event: Event) {
    throw new Error('Method not implemented.');
  }
  protected onChangeattachedDocumentReferenceNumber($event: Event) {
    throw new Error('Method not implemented.');
  }
  protected onChangeAttachedDocumentNumberOfOriginals($event: Event) {
    throw new Error('Method not implemented.');
  }

  protected onChangeExternalReferenceReceiver($event: Event) {
    throw new Error('Method not implemented.');
  }
  protected onChangeExternalReferenceSender($event: Event) {
    throw new Error('Method not implemented.');
  }

}