import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DateTimePipe } from "./date-time.pipe";
import { CustomerSgvNamePipe } from "./customer-sgv-name.pipe";
import { OrderNumberPipe } from 'src/app/shared/pipes/order-number.pipe';
import { WagonNumberPipe } from "src/app/shared/pipes/wagon-number.pipe";
import { TranslateWagonStatusPipe } from "./translate-wagon-status.pipe";
import { SuitableForRunningPipe } from "./suitable-for-running.pipe";
import { MarketareaCustomerNamePipe } from "src/app/trainorder/pipes/marketarea-customer-name.pipe";
import { TranslateOrderStatusPipe } from "./translate-order-status.pipe";
import { OrderNumberFormatterDirective } from "../directives/order-number.directive";
import { CustomerSgvPartnerIdPipe } from "./customer-sgv-partner-id.pipe";

@NgModule({
  imports: [
    CommonModule    
  ],
  declarations: [
    DateTimePipe,
    CustomerSgvNamePipe,
    OrderNumberPipe,
    WagonNumberPipe,
    TranslateWagonStatusPipe,
    TranslateOrderStatusPipe,
    SuitableForRunningPipe,
    MarketareaCustomerNamePipe,
    OrderNumberFormatterDirective,
    CustomerSgvPartnerIdPipe
  ],
  exports: [
    DateTimePipe,
    CustomerSgvNamePipe,
    OrderNumberPipe,
    WagonNumberPipe,
    TranslateWagonStatusPipe,
    TranslateOrderStatusPipe,
    SuitableForRunningPipe,
    MarketareaCustomerNamePipe,
    OrderNumberFormatterDirective,
    CustomerSgvPartnerIdPipe
  ],
  providers: [
    CustomerSgvNamePipe,
    OrderNumberPipe,
    WagonNumberPipe,
    TranslateWagonStatusPipe,
    TranslateOrderStatusPipe,
    SuitableForRunningPipe,
    MarketareaCustomerNamePipe,
    OrderNumberFormatterDirective
  ]
})
export class SharedPipesModule {

}