
export const limitTrainsList = 25;
export const limitOrdersList = 25;
export const limitOrderTemplatesList = 25;
export const trainListInterval = 120000;
export const trainListLimit = 10000;

export const customerSgvNameFormat = '($sgv) $name';
export const borderCodeNameFormat = '($sgv) $name';
export const marketareaCustomerNameFormat = '($marketareaCustomer) $name';
export const marketSegmentFormat = '($marketSegment) $name';
export const customerSgvPartnerId = '$customerName ($sgvId)-$siteName($partnerId)';