import { Subscription } from "rxjs";
import { Component, Input, OnInit, OnDestroy, inject } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

import { initalLoadingTackles, initalWagonInformation, LoadingTackles, RailOrder, WagonInformation } from "../../../../models/api-railorder";
import { WagonDataCommunicationService } from "../../service/wagon-data-communication.service";

export interface tackleType {
  key: string;
  value: any;
}

@Component({
  selector: 'app-loading-tackles-list',
  templateUrl: './loading-tackles-list.component.html',
  styleUrls: ['./loading-tackles-list.component.scss']
})
export class LoadingTacklesListComponent implements OnInit, OnDestroy {

  @Input() railOrder: RailOrder;
  protected formGroup: FormGroup;
  private sub = new Subscription(); // Simplified subscription handler
  protected tacklesTypeList: tackleType[] = [];

  // Injecting service in constructor and initializing wagonInformation
  private wagonInformation: WagonInformation = initalWagonInformation();
  private wagonDataCommunicationService = inject(WagonDataCommunicationService);

  constructor(private fb: FormBuilder) {}

  //#region CreateFormAndFillForm
  ngOnInit(): void {
    this.initForm();
    this.sub.add(
      this.wagonDataCommunicationService.currentWagonInformation$.subscribe({
        next: obj => {
          if (obj.componentName !== this.constructor.name) {
            this.wagonInformation = obj.wagonInformation || initalWagonInformation();
            console.log("wagonInformation changes read in loading tackles list component", this.wagonInformation);
            this.addLinesToForm();
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.sub.unsubscribe();
  }

  private addLinesToForm() {
    if (this.wagonInformation.loadingTackles.length === 0) {
      this.addNewLine();
    } else if (this.wagonInformation.loadingTackles.length !== this.loadingTacklesList.length) {
      this.setFormValues();
    }
  }
  //#endregion

  //#region Form Creation
  private initForm(): void {
    this.formGroup = this.fb.group({
      loadingTacklesList: this.fb.array([])
    });

    this.sub.add(
      this.formGroup.valueChanges.subscribe(() => {
        if (this.formGroup.valid) {
          this.valueChangeCallback();
        }
      })
    );
  }

  private valueChangeCallback() {
    this.wagonInformation.loadingTackles = this.getFormValues();
    this.wagonDataCommunicationService.changeWagonInformation(this.wagonInformation, this.constructor.name);
  }
  //#endregion

  //#region FillForm
  private setFormValues() {
    this.loadingTacklesList.clear();

    if (this.wagonInformation.loadingTackles?.length) {
      this.wagonInformation.loadingTackles.forEach((item: LoadingTackles) => {
        this.addNewLine(item);
      });
    } else {
      this.addNewLine();
    }
  }
  //#endregion

  //#region GetFormValues
  protected getFormValues(): LoadingTackles[] {
    return this.loadingTacklesList.controls.map((group: FormGroup) => ({
      number: group.get('numberOfLoadingTackles')?.value,
      type: group.get('type')?.value,
      weight: group.get('weight')?.value,
      identifier: group.get('identifier')?.value
    }));
  }
  //#endregion

  //#region Getter
  protected get loadingTacklesList(): FormArray {
    return this.formGroup.get('loadingTacklesList') as FormArray;
  }

  protected getControl(i: number, controlName: string): FormControl {
    return this.loadingTacklesList.at(i).get(controlName) as FormControl;
  }

  // Simplified getters for individual controls
  public getNumberOfLoadingTackles(i: number): FormControl {
    return this.getControl(i, 'numberOfLoadingTackles');
  }

  public getType(i: number): FormControl {
    return this.getControl(i, 'type');
  }

  public getIdentifier(i: number): FormControl {
    return this.getControl(i, 'identifier');
  }

  public getWeight(i: number): FormControl {
    return this.getControl(i, 'weight');
  }
  //#endregion

  //#region List Add/Remove
  protected addNewLine(item?: LoadingTackles): void {
    const newItem = item || initalLoadingTackles();
    
    const itemGroup: FormGroup = this.fb.group({
      numberOfLoadingTackles: new FormControl(newItem.number, Validators.required),
      type: new FormControl(newItem.type, Validators.required),
      identifier: new FormControl(newItem.identifier, Validators.required),
      weight: new FormControl(newItem.weight, Validators.required)
    });

    this.loadingTacklesList.push(itemGroup);

    if (!item) {
      this.wagonInformation.loadingTackles.push(newItem);
      this.valueChangeCallback(); // Ensure data is synced when adding a new line
    }
  }

  protected removeLine(idx: number): void {
    if (this.loadingTacklesList.length > 1) {
      this.loadingTacklesList.removeAt(idx);
      this.wagonInformation.loadingTackles.splice(idx, 1);
      this.valueChangeCallback(); // Sync the form with the updated data
    }
  }
  //#endregion

  //#region Validation
  isRowModified(rowIndex: number): boolean {
    return this.loadingTacklesList.at(rowIndex)?.dirty || false;
  }

  isControlInvalid(rowIndex: number, controlName: string): boolean {
    const control = this.getControl(rowIndex, controlName);
    return this.isRowModified(rowIndex) && control.invalid && (control.touched || control.dirty);
  }
  //#endregion
}
