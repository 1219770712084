import { Component, inject, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { ExceptionalConsignment, initalWagonInformation, initialExceptionalConsignment, RailOrder, WagonInformation } from "../../../../models/api-railorder";
import { DangerousGoodModel, ApiDangerousGoodResponse } from "src/app/trainorder/models/Cargo.model";
import { TrainorderService } from "src/app/trainorder/services/trainorder.service";
import { WagonDataCommunicationService } from "../../service/wagon-data-communication.service";

@Component({
  selector: 'app-authorization-list',
  templateUrl: './authorization-list.component.html',
  styleUrls: ['./authorization-list.component.scss']
})
export class AuthorizationListComponent implements OnInit, OnDestroy {

  @Input() railOrder: RailOrder;
  protected formGroup: FormGroup;
  private sub: Subscription = new Subscription();
  private wagonInformation: WagonInformation;
  private wagonDataCommunicationService: WagonDataCommunicationService = inject(WagonDataCommunicationService);

  //#region Declarations UN-Number Autocomplete
  // private subscriptionImCodeAutoComplete: Subscription = new Subscription();
  private imCodeInputChange: Subject<string> = new Subject<string>(); // | to debounce input requests
  protected imCodeAutocomplete: DangerousGoodModel[] = [];
  //#endregion

  constructor(private fb: FormBuilder, private trainorderService: TrainorderService) { }

  ngOnInit(): void {
    this.initForm();
    this.sub.add(
      this.wagonDataCommunicationService.currentWagonInformation$.subscribe({
        next: obj => {
          if (obj.componentName == this.constructor.name) {
            return;
          }
          this.wagonInformation = obj.wagonInformation ? obj.wagonInformation : initalWagonInformation();
          console.log("wagonInformation changes read in authorisation list component", this.wagonInformation);
          this.addLinesToForm();
        }
      }));
    // Initialize the IM Code autocomplete with debounce
    this.sub.add(
      this.imCodeInputChange.pipe(debounceTime(500)).subscribe((input) => {
        this.getImCodeAutocomplete(input);
      }));

    // Add a new line if authorizationList is empty
    if (this.authorizationList.length === 0) {
      this.addNewLine(null);
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  //#region CreateForm
  private initForm(): void {
    this.formGroup = this.fb.group({
      authorizationList: this.fb.array([])  // Initialize FormArray
    });
    
    this.sub.add(
      this.formGroup.valueChanges.pipe(debounceTime(500)).subscribe(v => {
        if (this.formGroup.valid) {
          this.valueChangeCallback();
        }
      })
    );
  }

  private valueChangeCallback() {
    this.wagonInformation.exceptionalConsignments = this.getFormValues();
    console.log("AuthorizationListComponent.valueChangeCallback: calling changeWagonInformation", this.constructor.name);
    this.wagonDataCommunicationService.changeWagonInformation(this.wagonInformation, this.constructor.name);
  }

  private addLinesToForm() {
    if (this.wagonInformation.exceptionalConsignments.length === 0) {
      this.addNewLine(null);
    } else if (this.wagonInformation.exceptionalConsignments.length != this.authorizationList.length) {
      this.setFormValues();
    }
  }
  //#endregion

  //#region Fillform

  //#region Fillform
  setFormValues() {
   
    // Clear the authorizationList only if it exists
    if (this.authorizationList && this.authorizationList.length > 0) {
      this.authorizationList.clear();
    }

    // Populate the form with values from wagonInformation, or add a new line
    if (this.wagonInformation && this.wagonInformation.exceptionalConsignments?.length) {
      this.wagonInformation.exceptionalConsignments.forEach((item: ExceptionalConsignment) => {
        this.addNewLine(item);
      });
    } else {
      this.addNewLine(null);  // Add an empty line if no consignments are provided
    }
  }

  //#endregion

  //#region getFormValues
  protected getFormValues(): ExceptionalConsignment[] {
    const formValues: ExceptionalConsignment[] = [];

    this.authorizationList.controls.forEach((group: FormGroup) => {
      const item: ExceptionalConsignment = {
        imCode: group.get('imCode')?.value,
        permissionNumber: group.get('permissionNumber')?.value
      };

      formValues.push(item);
    });

    return formValues;
  }
  //#endregion

  //#region Getter
  protected get authorizationList(): FormArray {
    return this.formGroup?.get('authorizationList') as FormArray;
  }

  // General dynamic getter method to get any form control from the FormArray
  protected getControl(i: number, controlName: string): FormControl {
    const group = this.authorizationList.at(i) as FormGroup;
    return group.get(controlName) as FormControl;
  }

  // Specific getters for imCode and permissionNumber using the dynamic getter
  protected getImCode(i: number): FormControl {
    return this.getControl(i, 'imCode');
  }

  protected getPermissionNumber(i: number): FormControl {
    return this.getControl(i, 'permissionNumber');
  }
  //#endregion

  //#region List add/remove
  protected addNewLine(item?: ExceptionalConsignment |null): void {
    if(item == null || !item) {
      item = initialExceptionalConsignment();
      this.wagonInformation.exceptionalConsignments.push(item);
    }

    const itemGroup: FormGroup = this.fb.group({
      imCode: new FormControl(item.imCode),
      permissionNumber: new FormControl(item.permissionNumber)
    });

    this.authorizationList.push(itemGroup);
  }

  protected removeLine(idx: number): void {
    if (this.authorizationList.length > 1) {
      this.authorizationList.removeAt(idx);
      this.wagonInformation.exceptionalConsignments.splice(idx, 1);
      this.wagonDataCommunicationService.changeWagonInformation(this.wagonInformation, this.constructor.name);
    }
  }
  //#endregion

  //#region Autocompleter

  /**
   * Emits the next input value from the field to trigger autocomplete
   * @param event
   * @param field type of the field
   */
  protected autocompleteInputChanged(event: any, field: string): void {
    switch (field) {
      case 'im-code':
        this.imCodeInputChange.next(event.target.value);
        break;

      default:
        break;
    }
  }

  /**
   * Fetches the IM Code autocomplete options.
   * Filters results and limits to 30 items for performance.
   * @param input The input string for autocomplete
   */
  private getImCodeAutocomplete(input: string): void {
    console.log('input:', input)
    if (input.length >= 3 && !this.imCodeAutocomplete.find((elem) => elem.unCode === input)) {
      this.trainorderService.getDangerousCargoInfo(input).then((result: ApiDangerousGoodResponse) => {
        console.log('result', result);
        this.imCodeAutocomplete = result.slice(0, 30).sort((a, b) => (a.unCode > b.unCode ? 1 : -1));
        console.log('Hello World', this.imCodeAutocomplete)
      });
    }
  }

  /**
   * TrackBy function for efficient rendering of dynamic lists
   * @param index 
   * @param item 
   * @returns 
   */
  protected trackByFn(index: any, item: any): any {
    return index;
  }

  //#endregion

}
