import { AfterViewInit, ChangeDetectorRef, Component, inject, Input, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ExternalReference, Goods, initalWagonInformation, initialDangerousGood, initialExternalReference, initialGood, initialPackingUnit, RailOrder, WagonInformation } from "../../../../models/api-railorder";
import { debounceTime, Subject, Subscription } from "rxjs";
import { ApiDangerousGoodResponse, ApiGoodResponse, DangerousGoodModel, GoodModel } from "src/app/trainorder/models/Cargo.model";
import { TrainorderService } from "src/app/trainorder/services/trainorder.service";
import { WagonDataCommunicationService } from "../../service/wagon-data-communication.service";


export interface emptyPackingUnit {
  key: string;
  value: any;
}

@Component({
  selector: 'app-goods-information-list',
  templateUrl: './goods-information-list.component.html',
  styleUrls: ['./goods-information-list.component.scss']
})
export class GoodsInformationListComponent implements OnInit, AfterViewInit {
  @Input() railOrder: RailOrder;

  protected formGroup: FormGroup;

  private wagonInformation: WagonInformation;
  private dangerousGoodIndex: number = 0;
  private wagonDataCommunicationService: WagonDataCommunicationService = inject(WagonDataCommunicationService);
  private subscriptions: Subscription = new Subscription();

  protected isDangerousGoods: boolean[] = [false, false, false, false];
  protected emptyPackingUnitList: emptyPackingUnit[] = [];

  //#region Autocomplete
  private nhmCodeInputChange: Subject<string> = new Subject<string>(); // | Used to track the input in the field
  private dangerousCargoInputChange: Subject<string> = new Subject<string>(); // | in order to have a delay between the requests
  private dangerousSpecialInstructionInputChange: Subject<string> = new Subject<string>(); // | in order to have a delay between the requests

  protected nhmCodeAutocomplete: GoodModel[] = [];
  protected dangerousGoodsAutocomplete: DangerousGoodModel[] = [];
  protected dangerousSpecialInstructionAutocomplete: DangerousGoodModel[] = [];
  //#endregion

  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private trainorderService: TrainorderService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.subscriptions.add(
      this.wagonDataCommunicationService.currentWagonInformation$.subscribe({
        next: obj => {
          if (obj.componentName == this.constructor.name) return;
          this.goodsInformationList.clear();
          this.wagonInformation = obj.wagonInformation;
          if (this.wagonInformation?.goods?.length === 0) {
            this.addNewLine(null);
          } else {
            this.wagonInformation?.goods.forEach((item: Goods) => {
              this.addNewLine(item);
            });
          }
        }
      }));

    console.log("this.wagonInformation", this.wagonInformation);

    this.subscriptions.add(this.nhmCodeInputChange.pipe(debounceTime(500)).subscribe((input) => {
      this.getCargoInfoAutocomplete(input);
    }));

    this.subscriptions.add(this.dangerousCargoInputChange.pipe(debounceTime(500)).subscribe((input) => {
      this.getDangerousCargoInfoAutocomplete(input);
    }));

    this.subscriptions.add(this.dangerousSpecialInstructionInputChange.pipe(debounceTime(500)).subscribe((input) => {
      this.getSpecialInstructionAutocomplete(input);
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  //#region CreateFormAndFillForm
  private initForm(): void {
    this.formGroup = this.fb.group({
      goodsInformationList: this.fb.array([])
    });
    this.subscriptions.add(
      this.formGroup.get('goodsInformationList').valueChanges.subscribe(v => {
        if (this.formGroup.valid) {
          this.valueChangeCallback();
        }
      }));
  }

  private valueChangeCallback() {
    this.wagonInformation.goods = this.getFormValues();
    console.log("GoodsInformationListComponent valueChanges callschangeWagonInformation ");
    this.wagonDataCommunicationService.changeWagonInformation(this.wagonInformation, this.constructor.name);
  }

  protected addNewLine(good?: Goods | null): void {

    if (good == null) {
      good = initialGood();
      this.wagonInformation.goods.push(good);
      // this.wagonDataCommunicationService.changeWagonInformation(this.wagonInformation, this.constructor.name);
    }

    const itemGroup: FormGroup = this.fb.group({});
    for (let good of this.wagonInformation.goods) {
      itemGroup.addControl('nhmCode', new FormControl(good.nhmCode));
      itemGroup.addControl('weight', new FormControl(good.weight));
      itemGroup.addControl('volume', new FormControl(good.volume))
      itemGroup.addControl('unit', new FormControl(good.unit));
      itemGroup.addControl('additionalDeclarationCode', new FormControl(good.additionalDeclarationCode));
      itemGroup.addControl('wasteId', new FormControl(good.wasteId));
      itemGroup.addControl('customsReferenceNumber', new FormControl(good.customsReferenceNumber));
      itemGroup.addControl('additionalDescription', new FormControl(good.additionalDescription));
      if (good?.externalReferences?.filter((el) => el.type === "MRN").length > 0) {
        good.externalReferences.forEach((ref: ExternalReference) => {
          if (ref.type === "MRN") {
            itemGroup.addControl('externalReferenceSubType', new FormControl(ref?.subType));
            itemGroup.addControl('externalReferenceIdentifier', new FormControl(ref?.identifier));
          }
        });
      } else {
        const ref = (initialExternalReference());
        ref.type = 'MRN';
        good?.externalReferences?.push(ref);
        itemGroup.addControl('externalReferenceSubType', new FormControl(''));
        itemGroup.addControl('externalReferenceIdentifier', new FormControl(''));
      }
      if (good.externalReferences?.length == 0) {
        good.packingUnits.push(initialPackingUnit());

      }
      itemGroup.addControl('packingUnitsType', new FormControl(good.packingUnits[0]?.type));
      itemGroup.addControl('packingUnitsNumber', new FormControl(good.packingUnits[0]?.number));

      if (!good.dangerousGoods) {
        good.dangerousGoods.push(initialDangerousGood());
      }

      const dangerousGood = good.dangerousGoods[0];
      itemGroup.addControl('unNr', new FormControl(dangerousGood?.unNr));
      itemGroup.addControl('specialInstruction', new FormControl(dangerousGood?.specialInstruction));
      itemGroup.addControl('emptyPackingUnit', new FormControl(dangerousGood?.emptyPackingUnit));
      itemGroup.addControl('explosiveMass', new FormControl(dangerousGood?.explosiveMass));
      itemGroup.addControl('transportProhibited', new FormControl(dangerousGood?.transportProhibited));
      itemGroup.addControl('wasteIndicator', new FormControl(dangerousGood?.wasteIndicator));
      itemGroup.addControl('accidentInformationSheetAvailable', new FormControl(dangerousGood?.accidentInformationSheetAvailable));
      itemGroup.addControl('additionalInformation', new FormControl(dangerousGood?.additionalInformation));
      itemGroup.addControl('specialInstructionSelect', new FormControl(dangerousGood?.specialInstruction));
      itemGroup.addControl('dangerIdentificationNumber', new FormControl(dangerousGood?.dangerIdentificationNumber));
      itemGroup.addControl('description', new FormControl(dangerousGood?.description));
      itemGroup.addControl('classificationCode', new FormControl(dangerousGood?.classificationCode));
      itemGroup.addControl('packingGroup', new FormControl(dangerousGood?.packingGroup));
      itemGroup.addControl('dangerLabels', new FormControl(dangerousGood?.dangerLabels));
      itemGroup.addControl('accidentInformationSheetNr', new FormControl(dangerousGood?.accidentInformationSheetNr));
    }
    this.goodsInformationList.push(itemGroup);
  }

  protected removeLine(idx: number): void {
    if (this.goodsInformationList.length > 1) {
      this.goodsInformationList.removeAt(idx);
      this.wagonDataCommunicationService.changeWagonInformation(this.wagonInformation, this.constructor.name);
    }
  }

  protected get goodsInformationList(): FormArray {
    return this.formGroup?.get('goodsInformationList') as FormArray;
  }
  //#endregion


  //#region GetFormValues
  private getFormValues(): Goods[] {
    const formValues: Goods[] = [];
    this.goodsInformationList.controls.forEach((group: FormGroup) => {
      const item: Goods = {
        nhmCode: group.get('nhmCode')?.value,
        weight: group.get('weight')?.value,
        volume: group.get('volume')?.value,
        unit: group.get('unit')?.value,
        additionalDeclarationCode: group.get('additionalDeclarationCode')?.value,
        wasteId: group.get('wasteId')?.value,
        customsReferenceNumber: group.get('customsReferenceNumber')?.value,
        additionalDescription: group.get('additionalDescription')?.value,
        externalReferences: [
          {
            type: 'MRN',
            subType: group.get('externalReferenceSubType')?.value,
            identifier: group.get('externalReferenceIdentifier')?.value
          }
        ],
        packingUnits: [
          {
            number: group.get('packingUnitsNumber')?.value,
            type: group.get('packingUnitsType')?.value

          }
        ],
        dangerousGoods: [
          {
            unNr: group.get('unNr')?.value,
            specialInstruction: group.get('specialInstruction')?.value,
            emptyPackingUnit: group.get('emptyPackingUnit')?.value,
            explosiveMass: group.get('explosiveMass')?.value,
            transportProhibited: group.get('transportProhibited')?.value,
            wasteIndicator: group.get('wasteIndicator')?.value,
            accidentInformationSheetAvailable: group.get('accidentInformationSheetAvailable')?.value,
            additionalInformation: group.get('additionalInformation')?.value,
            dangerIdentificationNumber: group.get('dangerIdentificationNumber')?.value,
            description: group.get('description')?.value,
            classificationCode: group.get('classificationCode')?.value,
            packingGroup: group.get('packingGroup')?.value,
            dangerLabels: group.get('dangerLabels')?.value?.split('/', 4),
            accidentInformationSheetNr: group.get('accidentInformationSheetNr')?.value
          }
        ]
      }

      formValues.push(item);
    });
    return formValues;
  }
  //#endregion



  //#region Autocompleter
  /**
     * Emits the next input value from the field
     * @param event
     * @param field type of the field
     */
  protected autocompleteInputChanged(event: any, field: string): void {
    switch (field) {
      case 'nhm-code':
        this.nhmCodeInputChange.next(event.target.value);
        break;
      case 'dangerous-cargo':
        this.dangerousCargoInputChange.next(event.target.value);
        break;
      case 'dangerous-special-provision':
        this.dangerousSpecialInstructionInputChange.next(event.target.value);
        break;
      default:
        break;

    }
  }

  protected trackByFn(index: any, item: any): any {
    return index;
  }

  private getCargoInfoAutocomplete(input: any): void {
    if (input.length >= 3 && !this.nhmCodeAutocomplete.find((elem) => elem.nhmCode === input)) {
      this.trainorderService.getCargoInfo(input).then((result: ApiGoodResponse) => {
        // Take only 30 answers that fit (array may be 1000+ in length), otherwise it takes a lot of resources to build these elements
        this.nhmCodeAutocomplete = result.slice(0, 30).sort((a, b) => (a.nhmCode > b.nhmCode ? 1 : -1));
      });
    }
  }

  private getDangerousCargoInfoAutocomplete(input: any): void {
    if (input.length >= 3 && !this.dangerousGoodsAutocomplete.find((elem) => elem.unCode === input))
      // TODO: add description in the input as well
      this.trainorderService.getDangerousCargoInfo(input).then((result: ApiDangerousGoodResponse) => {
        // Take only 30 answers that fit (array may be 1000+ in length), otherwise it takes a lot of resources to build these elements
        this.dangerousGoodsAutocomplete = result.slice(0, 30).sort((a, b) => (a.unCode > b.unCode ? 1 : -1));
      });
  }

  private getSpecialInstructionAutocomplete(input: any): void {
    if (input.length >= 3 && !this.dangerousSpecialInstructionAutocomplete.find((elem) => elem.unCode === input))
      // TODO: add description in the input as well
      this.trainorderService.getDangerousCargoInfo(input).then((result: ApiDangerousGoodResponse) => {
        // Take only 30 answers that fit (array may be 1000+ in length), otherwise it takes a lot of resources to build these elements
        this.dangerousSpecialInstructionAutocomplete = result.slice(0, 30).sort((a, b) => (a.unCode > b.unCode ? 1 : -1));
      });
  }

  //#endregion



  //#region FormControl Get
  getdangerLabels1to4(idx: number): string {
    const dangerLabels = this.wagonInformation.goods[idx]?.dangerousGoods[this.dangerousGoodIndex]?.dangerLabels
    if (dangerLabels) {
      return dangerLabels.slice(0, 4).filter(label => label).join('/');
    }
    return ''; // Return empty string if dangerLabels is not available
  }

  getWagonInformationGoodsWeight(index: number): FormControl {
    return this.goodsInformationList.at(index).get('wagonInformationGoodsWeight') as FormControl;
  }

  getWagonInformationGoodsVolume(index: number): FormControl {
    return this.goodsInformationList.at(index).get('wagonInformationGoodsVolume') as FormControl;
  }

  getWagonInformationGoodsUnit(index: number): FormControl {
    return this.goodsInformationList.at(index).get('wagonInformationGoodsUnit') as FormControl;
  }

  protected getControl(index: number, controlName: string): FormControl {
    return this.goodsInformationList.at(index).get(controlName) as FormControl; // Getter for specific control
  }
  //#endregion

  //#region Validation
  protected isControlInvalid(Index: number, controlName: string): boolean {
    const control = this.getControl(Index, controlName);

    return false
  }

  groupFieldIsInvalid(arg0: string) {
    //throw new Error('Method not implemented.');
  }
  //#endregion

  //#region FormSteuerung
  protected toggleDangerousGoods(idx: number): void {
    this.isDangerousGoods[idx] = !this.isDangerousGoods[idx];
  }
  //#endregion
}