import { Component, inject, Input, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";

import { initalWagonInformation, initialSeal, RailOrder, Seal, WagonInformation } from "../../../../models/api-railorder";
import { WagonDataCommunicationService } from "../../service/wagon-data-communication.service";

@Component({
  selector: 'app-sealing-list',
  templateUrl: './sealing-list.component.html',
  styleUrls: ['./sealing-list.component.scss']
})

export class SealingListComponent implements OnInit, OnDestroy {
  @Input() railOrder: RailOrder;

  protected formGroup: FormGroup;
  private wagonInformation: WagonInformation = initalWagonInformation();
  private sub = new Subscription(); // Unified subscription handler
  private wagonDataCommunicationService = inject(WagonDataCommunicationService);

  constructor(private fb: FormBuilder) {}

  //#region CreateFormAndFillForm
  ngOnInit(): void {
    this.initForm();
    this.sub.add(
      this.wagonDataCommunicationService.currentWagonInformation$.subscribe({
        next: obj => {
          if (obj.componentName !== this.constructor.name) {
            this.wagonInformation = obj.wagonInformation || initalWagonInformation();
            console.log("wagonInformation changes read in sealing list component", this.wagonInformation);
            this.addLinesToForm();
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.sub.unsubscribe();
  }

  private addLinesToForm() {
    if (this.wagonInformation.seals.length === 0) {
      this.addNewLine();
    } else if (this.wagonInformation.seals.length !== this.sealingList.length) {
      this.setFormValues();
    }
  }
  //#endregion

  //#region Form Creation
  private initForm(): void {
    this.formGroup = this.fb.group({
      sealingList: this.fb.array([])
    });

    this.sub.add(
      this.formGroup.valueChanges.subscribe(() => {
        if (this.formGroup.valid) {
          this.valueChangeCallback();
        }
      })
    );
  }

  private valueChangeCallback() {
    this.wagonInformation.seals = this.getFormValues();
    this.wagonDataCommunicationService.changeWagonInformation(this.wagonInformation, this.constructor.name);
  }
  //#endregion

  //#region FillForm
  private setFormValues() {
    this.sealingList.clear();

    if (this.wagonInformation.seals?.length) {
      this.wagonInformation.seals.forEach((item: Seal) => {
        this.addNewLine(item);
      });
    } else {
      this.addNewLine();
    }
  }
  //#endregion

  //#region GetFormValues
  protected getFormValues(): Seal[] {
    return this.sealingList.controls.map((group: FormGroup) => ({
      type: group.get('type')?.value,
      referenceNumber: group.get('referenceNumber')?.value
    }));
  }
  //#endregion

  //#region Getter
  protected get sealingList(): FormArray {
    return this.formGroup.get('sealingList') as FormArray;
  }

  protected getControl(i: number, controlName: string): FormControl {
    return this.sealingList.at(i).get(controlName) as FormControl;
  }

  public getType(i: number): FormControl {
    return this.getControl(i, 'type');
  }

  public getReferenceNumber(i: number): FormControl {
    return this.getControl(i, 'referenceNumber');
  }
  //#endregion

  //#region List Add/Remove
  protected addNewLine(item?: Seal): void {
    const newItem = item || initialSeal();

    const itemGroup: FormGroup = this.fb.group({
      type: new FormControl(newItem.type, [Validators.required, Validators.minLength(10)]),
      referenceNumber: new FormControl(newItem.referenceNumber, Validators.required)
    });

    this.sealingList.push(itemGroup);

    if (!item) {
      this.wagonInformation.seals.push(newItem);
      this.valueChangeCallback(); // Ensure data is synced when adding a new line
    }
  }

  protected removeLine(idx: number): void {
    if (this.sealingList.length > 1) {
      this.sealingList.removeAt(idx);
      this.wagonInformation.seals.splice(idx, 1);
      this.valueChangeCallback(); // Sync the form with the updated data
    }
  }
  //#endregion

  //#region Validation
  isRowModified(rowIndex: number): boolean {
    return this.sealingList.at(rowIndex)?.dirty || false;
  }

  isControlInvalid(rowIndex: number, controlName: string): boolean {
    const control = this.getControl(rowIndex, controlName);
    return this.isRowModified(rowIndex) && control.invalid && (control.touched || control.dirty);
  }
  //#endregion
}
