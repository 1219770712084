import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, Observable, of } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

import { CustomerSelectComponent } from 'src/app/shared/components/customer-select/customer-select.component';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { EnvService } from '../../services/env/env.service';
import { AuthService } from '../../services/auth/auth.service';
import { PermissionService } from '../../permission/PermissionService';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @ViewChild('customer_select', { static: false }) customerSelection!: CustomerSelectComponent;
  protected selectedMenuItem: string | null = null;
  public isMenuOpened: boolean = false;
  protected nickname: Observable<string>;
  protected showCustomerSelect: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public oauthService: OAuthService,
    private authService: AuthService,
    private storageService: LocalStorageService,
    private envService: EnvService,
    private router: Router,
    protected permissionService: PermissionService
  ) { }

  ngOnInit() {
    if (this.storageService.getUsername()) {
      this.nickname = of(this.storageService.getUsername() || '');
    } else {
      this.storageService.watchLocalStorage().subscribe(_ => this.nickname = of(this.storageService.getUsername() || ''));
    }

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // Update header visibility based on the URL
        if(event.url.startsWith('/gzp/') || (event.urlAfterRedirects && event.urlAfterRedirects.startsWith('/gzp/'))) {
          this.showCustomerSelect.next(true);
        } else {
          this.showCustomerSelect.next(false);
        }
      });
  }

  protected tryLogin() {
    this.oauthService.logOut(true);
    this.authService.tryLogin();
  }

  protected logout() {
    this.authService.logoutAndTryLogin();
  }

  public closeMenu(): void {
    this.isMenuOpened = false;
  }

  protected isLoggedIn(): boolean {
    const hasValidToken = this.oauthService.getAccessToken() != null;

    return hasValidToken;
  }
}
