// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `wagon-list-nr {
  padding: 30px 0px 0px 0px;
}

/* Styles for enabled state of the specific button */
button {
  background-color: white !important; /* Set your desired background for enabled state */
}

/* Styles for disabled state of the specific button */
button:disabled {
  background-color: white !important; /* Override with white for disabled */
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
