import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SectionName } from '../enums/order-enums';
import { initialRailOrder } from '../models/api-railorder';
import { NewOrderConsignorConsigneeComponent } from '../new-order-sections/new-order-consignor-consignee/new-order-consignor-consignee.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { NewOrderPickupDeliveryComponent } from '../new-order-sections/new-order-pickup-delivery/new-order-pickup-delivery.component';
import { NewOrderTransportComponent } from '../new-order-sections/new-order-transport/new-order-transport.component';
import { NewOrderCommercialComponent } from '../new-order-sections/new-order-commercial/new-order-commercial.component';
import { NewOrderSenderPolicyComponent } from '../new-order-sections/new-order-sender-policy/new-order-sender-policy.component';
import { NewOrderServiceComponent } from '../new-order-sections/new-order-service/new-order-service.component';
import { NewOrderWagonDataComponent } from '../new-order-sections/new-order-wagon-data/new-order-wagon-data.component';
import { NewOrderSenderPolicyModule } from '../new-order-sections/new-order-sender-policy/new-order-sender-policy.modules';
import { NewOrderCommercialModule } from '../new-order-sections/new-order-commercial/new-order-commercial.modules';
import { NewOrderConsignorConsigneeModule } from '../new-order-sections/new-order-consignor-consignee/new-order-consignor-consignee.modules';
import { NewOrderPickupDeliveryModule } from '../new-order-sections/new-order-pickup-delivery/new-order-pickup-delivery.modules';
import { NewOrderServiceModule } from '../new-order-sections/new-order-service/new-order-service.modules';
import { NewOrderTransportModule } from '../new-order-sections/new-order-transport/new-order-transport.modules';
import { NewOrderWagonDataModule } from '../new-order-sections/new-order-wagon-data/new-order-wagon-data.modules';
import { OrderInfoData } from '../models/order-info-data.model';
import { PrepareOrderSessionMocks } from 'src/app/order-management/mock/order-management-mock';
import { DynamicStorageService } from '../service/dynamic-session-storage.service';

@Component({
  selector: 'app-new-order-main',
  templateUrl: './new-order-main.component.html',
  styleUrl: './new-order-main.component.scss',
  standalone: true,
  imports: [
    SharedModule,
    MatDialogModule,
    TranslateModule,
    NewOrderConsignorConsigneeModule,
    NewOrderPickupDeliveryModule,
    NewOrderTransportModule,
    NewOrderWagonDataModule,
    NewOrderCommercialModule,
    NewOrderServiceModule,
    NewOrderSenderPolicyModule
  ]

})
export class NewOrderMainComponent implements AfterViewInit {
  clearSearchInput(arg0: string) {
    throw new Error('Method not implemented.');
  }

  onChangeOrderCode($event: Event) {
    throw new Error('Method not implemented.');
  }
  @ViewChild(NewOrderConsignorConsigneeComponent, { static: false }) sectionConsigneeConsignor!: NewOrderConsignorConsigneeComponent;
  @ViewChild(NewOrderPickupDeliveryComponent, { static: false }) sectionPickupDelivery!: NewOrderPickupDeliveryComponent;
  @ViewChild(NewOrderTransportComponent, { static: false }) sectionTransport!: NewOrderTransportComponent;
  @ViewChild(NewOrderWagonDataComponent, { static: false }) sectionWagonData!: NewOrderWagonDataComponent;
  @ViewChild(NewOrderCommercialComponent, { static: false }) sectionCommercial!: NewOrderCommercialComponent;
  @ViewChild(NewOrderServiceComponent, { static: false }) sectionService!: NewOrderServiceComponent;
  @ViewChild(NewOrderSenderPolicyComponent, { static: false }) sectionSenderPolicy!: NewOrderSenderPolicyComponent;

  protected orderInfoData: OrderInfoData;
  protected currentSectionName: SectionName = SectionName.SECTION_CONSIGNEE_CONSIGNOR;
  protected SectionName = SectionName;
  protected formGroup: FormGroup;
  protected nextIsVisibel: boolean = true;
  protected backIsVisibel: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) private data: OrderInfoData, private dynamicStorageService: DynamicStorageService) {
    const prepareSessionMocks = new PrepareOrderSessionMocks(dynamicStorageService)
    prepareSessionMocks.prepareSessionParameter();
  }
  ngAfterViewInit(): void {
    this.sectionConsigneeConsignor.updateRailOrder(this.orderInfoData.railOrder);
  }

  ngOnInit() {
    if (this.data && this.data.isNew === true) {
      this.orderInfoData = {
        isNew: this.data.isNew,
        railOrder: initialRailOrder()
      }
    } else {
      // order object laden
    }

    this.setFocus();
    this.createForm();
  }

  private createForm(): void {
    this.formGroup = new FormGroup(
      {
        fieldOrderCode: new FormControl(''),
        singleConsignmentNote: new FormControl(''),

      }
    );
  }

  protected setSection(selectedSection: SectionName): void {
    this.currentSectionName = selectedSection;
    this.setFocus();
    this.setButtomvisibility();
    switch (selectedSection) {
      case (SectionName.SECTION_CONSIGNEE_CONSIGNOR):
        this.sectionConsigneeConsignor.updateRailOrder(this.orderInfoData.railOrder);
        break;
      case (SectionName.SECTION_PICKUP_DELIVERY):
        this.sectionPickupDelivery.updateRailOrder(this.orderInfoData.railOrder);
        break;
      case (SectionName.SECTION_TRANSPORT):
        this.sectionTransport.updateRailOrder(this.orderInfoData.railOrder);
        break;
      case (SectionName.SECTION_WAGON_DATA):
        this.sectionWagonData.updateRailOrder(this.orderInfoData.railOrder);
        break;
      case (SectionName.SECTION_COMMERCIAL):
        this.sectionCommercial.updateRailOrder(this.orderInfoData.railOrder);
        break;
      case (SectionName.SECTION_SERVICE):
        this.sectionService.updateRailOrder(this.orderInfoData.railOrder);
        break;
      case (SectionName.SECTION_SENDER_POLICY):
        this.sectionSenderPolicy.updateRailOrder(this.orderInfoData.railOrder);
        break;
    }

  }

  protected setButtomvisibility(): void {
    switch (this.currentSectionName) {
      case (SectionName.SECTION_CONSIGNEE_CONSIGNOR):
        this.backIsVisibel = false;
        this.nextIsVisibel = true;
        break;
      case (SectionName.SECTION_PICKUP_DELIVERY):
        this.backIsVisibel = true;
        this.nextIsVisibel = true;
        break;
      case (SectionName.SECTION_TRANSPORT):
        this.backIsVisibel = true;
        this.nextIsVisibel = true;
        break;
      case (SectionName.SECTION_WAGON_DATA):
        this.backIsVisibel = true;
        this.nextIsVisibel = true;
        break;
      case (SectionName.SECTION_COMMERCIAL):
        this.backIsVisibel = true;
        this.nextIsVisibel = true;
        break;
      case (SectionName.SECTION_SERVICE):
        this.backIsVisibel = true;
        this.nextIsVisibel = true;
        break;
      case (SectionName.SECTION_SENDER_POLICY):
        this.backIsVisibel = true;
        this.nextIsVisibel = false;
        break;
    }
  }

  protected nextSection() {
    switch (this.currentSectionName) {
      case (SectionName.SECTION_CONSIGNEE_CONSIGNOR):
        this.currentSectionName = SectionName.SECTION_PICKUP_DELIVERY
        this.backIsVisibel = true;
        break;
      case (SectionName.SECTION_PICKUP_DELIVERY):
        this.currentSectionName = SectionName.SECTION_TRANSPORT
        break;
      case (SectionName.SECTION_TRANSPORT):
        this.currentSectionName = SectionName.SECTION_WAGON_DATA
        break;
      case (SectionName.SECTION_WAGON_DATA):
        this.currentSectionName = SectionName.SECTION_COMMERCIAL
        break;
      case (SectionName.SECTION_COMMERCIAL):
        this.currentSectionName = SectionName.SECTION_SERVICE
        break;
      case (SectionName.SECTION_SERVICE):
        this.currentSectionName = SectionName.SECTION_SENDER_POLICY
        this.nextIsVisibel = false;
        break;
    }
    this.setFocus();
  }

  protected backSection() {
    switch (this.currentSectionName) {
      case (SectionName.SECTION_PICKUP_DELIVERY):
        this.currentSectionName = SectionName.SECTION_CONSIGNEE_CONSIGNOR
        this.backIsVisibel = false;
        break;
      case (SectionName.SECTION_TRANSPORT):
        this.currentSectionName = SectionName.SECTION_PICKUP_DELIVERY
        break;
      case (SectionName.SECTION_WAGON_DATA):
        this.currentSectionName = SectionName.SECTION_TRANSPORT
        break;
      case (SectionName.SECTION_COMMERCIAL):
        this.currentSectionName = SectionName.SECTION_WAGON_DATA
        break;
      case (SectionName.SECTION_SERVICE):
        this.currentSectionName = SectionName.SECTION_COMMERCIAL
        break;
      case (SectionName.SECTION_SENDER_POLICY):
        this.currentSectionName = SectionName.SECTION_SERVICE
        this.nextIsVisibel = true;
        break;
    }
    this.setFocus();
  }

  private setFocus() {
    document.querySelectorAll<HTMLElement>('.tabs-item').forEach(el => {
      el.classList.remove('tabs-item-selected');
      el.classList.remove('tabs-item-valid');
      if (el.id == this.currentSectionName) {
        el.classList.add('tabs-item-selected');
      }
    });
  }

  public validate(): boolean {
    let sectionConsigneeConsignorResult = this.sectionConsigneeConsignor.validate();
    if (sectionConsigneeConsignorResult?.length > 0) {
      console.log(sectionConsigneeConsignorResult);
      return false;
    }
    /*
    let section_2_result = this.section_2.validate();
    if (section_2_result?.length > 0) {
      console.log(section_2_result);
      return false;
    }
    let section_3_result = this.section_3.validate();
    if (section_3_result?.length > 0) {
      console.log(section_3_result);
      return false;
    }
      */
    return true;
  }

  sendNewOrderRequest() {
    throw new Error('Method not implemented.');
  }

}
