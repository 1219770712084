import { ChangeDetectorRef, Component, Injector, Input, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators'; // If you're using RxJS pipeable operators

import { SectionBase } from '../section.base';
import { SectionName } from '../../enums/order-enums';
import { RailOrder, SpecialTreatmentOrder } from '../../models/api-railorder';
import { NewOrderMainComponent } from '../../new-order-main/new-order-main.component';
import { DynamicStorageService } from '../../service/dynamic-session-storage.service';
import { KeyValuePair, RAILORDER_AUTHORITYLIST_LISTNAME, RAILORDER_SERVICE_PAYMENTMETHOD_TYPES_LISTNAME, RAILORDER_SERVICE_SERVICE_TYPES_LISTNAME } from '../../models/api-dynamic-storage';
import { BasicLocation } from '../../models/new-order.model';
import { InfrastructureLocation, InfrastructureLocationResponse, StationType } from 'src/app/trainorder/models/location.models';
import { TrainorderService } from 'src/app/trainorder/services/trainorder.service';


@Component({
  selector: 'app-new-order-service',
  templateUrl: './new-order-service.component.html',
  styleUrls: ['../../new-order-main/new-order-main.component.scss',
    './new-order-service.component.scss'
  ]
})
export class NewOrderServiceComponent extends SectionBase {
  railOrder: RailOrder;

  @Input() currentSectionName: SectionName;

  protected formGroup: FormGroup;
  protected SectionName = SectionName;
  protected parent: NewOrderMainComponent;

  protected serviceTypeList: KeyValuePair[] = [];
  protected authorityList = [];
  protected prepaymentTypeList: KeyValuePair[] = [];

  protected locationCodeAutocomplete: BasicLocation[] = [];
  private locationCodeInputChange: Subject<string> = new Subject<string>();
  private subscription: Subscription = new Subscription();

  constructor(private injector: Injector,
    private formBuilder: FormBuilder,
    private dynamicStorageService: DynamicStorageService,
    private cd: ChangeDetectorRef,
    // nur für Mock
    private trainOrderService: TrainorderService,
  ) {
    super();
    this.parent = this.injector.get<NewOrderMainComponent>(NewOrderMainComponent);
    this.loadLists();
    this.createForm();
  }

  ngOnDestroy(): void {
    this.registerForLocationCodeInputChanges();
  }


  public updateRailOrder(ro: RailOrder) {
    console.log(ro);
    this.railOrder = ro;
    this.setFormValues();
    this.cd.detectChanges();
  }

  private registerForLocationCodeInputChanges(): void {
    this.subscription.add(
      this.locationCodeInputChange.pipe(debounceTime(500)).subscribe((input) => {
        if (input?.length === 0) {
          this.locationCodeAutocomplete = [];
        } else {
          const item = this.locationCodeAutocomplete.find(station => { return station.name === input; });
          if (item) {
            this.locationCodeAutocomplete = [];
          } else {
            this.getAutocompleteSuggestions(input);
          }
        }
      })
    );
  }

  setFormValues() {
    //throw new Error('Method not implemented.');
  }

  private createForm(): void {
    this.formGroup = new FormGroup({
      serviceSpecification: this.formBuilder.array([]),
      //productExtraChargeCode: new FormControl(''),
      //authority: new FormControl(''),
      //locationCode: new FormControl(''),
      //specialTreatmentChargingPrepayment: new FormControl(''),
      //info: new FormControl('')
    });
    this.addnewLine();
  }

  private loadLists(): void {
    this.dynamicStorageService.retrieveKeyValueList(RAILORDER_AUTHORITYLIST_LISTNAME)
      .subscribe({
        next: data => this.authorityList = data || [],
        error: err => console.error(`Failed to load transportation type list: ${err}`)
      });

    this.dynamicStorageService.retrieveKeyValueList(RAILORDER_SERVICE_PAYMENTMETHOD_TYPES_LISTNAME)
      .subscribe({
        next: data => this.prepaymentTypeList = data || [],
        error: err => console.error(`Failed to load type of transport code list: ${err}`)
      });

    this.dynamicStorageService.retrieveKeyValueList(RAILORDER_SERVICE_SERVICE_TYPES_LISTNAME)
      .subscribe({
        next: data => this.serviceTypeList = data || [],
        error: err => console.error(`Failed to load dangerous good law list: ${err}`)
      });
  }

  public onChangeOrder(): void {
    //this.order.id = 100;
    //this.parent.changeOrder(this.order);
  }

  public validate(): string[] {
    console.log('validate consignor-consignee section');
    return [];
  }

  protected addnewLine(item?: SpecialTreatmentOrder): void {
    const itemGroup: FormGroup = this.formBuilder.group({});
    if (item) {
      itemGroup.addControl('productExtraChargeCode', new FormControl(item.productExtraChargeCode));
      itemGroup.addControl('authority', new FormControl(item.authority));
      itemGroup.addControl('locationCode', new FormControl(item.locationCode));
      itemGroup.addControl('specialTreatmentChargingPrepayment', new FormControl(item.specialTreatmentChargings[0].prepayment));
      itemGroup.addControl('info', new FormControl(item.info));

    } else {
      itemGroup.addControl('productExtraChargeCode', new FormControl());
      itemGroup.addControl('authority', new FormControl());
      itemGroup.addControl('locationCode', new FormControl());
      itemGroup.addControl('specialTreatmentChargingPrepayment', new FormControl());
      itemGroup.addControl('info', new FormControl());
    }
    if (this.serviceSpecification.length < 8) {
      this.serviceSpecification.push(itemGroup);
    }
  }

  removeLine(index: number) {
    if (this.serviceSpecification.length > 1) {
      this.serviceSpecification.removeAt(index);
    }
  }

  protected get serviceSpecification(): FormArray {
    return this.formGroup.get('serviceSpecification') as FormArray;
  }
  /*
    private get productExtraChargeCode(): FormControl {
      return this.formGroup.get('productExtraChargeCode') as FormControl;
    }
  
    private get authority(): FormControl {
      return this.formGroup.get('authority') as FormControl;
    }
  
    private get locationCode(): FormControl {
      return this.formGroup.get('locationCode') as FormControl;
    }
  
    private get specialTreatmentChargingPrepayment(): FormControl {
      return this.formGroup.get('specialTreatmentChargingPrepayment') as FormControl;
    }
  
    private get info(): FormControl {
      return this.formGroup.get('info') as FormControl;
    }
  */

  protected getAutocompleteSuggestions(input: any): void {
    let autocompleteArray: InfrastructureLocation[] = [];
    let apiStationType = StationType.DEPARTURE;
    this.subscription.add(this.trainOrderService.getTrainsInfrastructureLocations(input, apiStationType, autocompleteArray).subscribe((result: InfrastructureLocationResponse) => {
      this.locationCodeAutocomplete = this.trainOrderService.createUniqueKeysInfrastructureLocations(result);
    }));
  }

  protected clearSearchInput() {
    throw new Error('Method not implemented.');
  }

  protected autocompletelocationCode($event: Event) {
    throw new Error('Method not implemented.');
  }
}