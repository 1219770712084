// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fr-100-gap-10 {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
}

.fr-100-gap-10-no-justify-content {
  display: flex;
  gap: 10px;
  width: 100%;
}

.fr-flex-0 {
  flex: 0;
}

.fr-flex-1 {
  flex: 1;
}

.popup {
  overflow-y: hidden;
}

.button-navigation {
  display: flex;
  padding: 10px;
  gap: 20px;
}

.first-section,
.last-section {
  display: flex;
}

.last-section {
  flex-direction: column;
  padding: 0px 30px 0px 0px;
}

.close-section {
  margin-left: 50px;
}

/* Sidebar (Sticky) */
.tabs-sticky {
  counter-reset: my-tabs-counter;
  width: 230px; /* Sidebar width */
  position: sticky;
  top: 0;
  overflow-y: auto;
  background-color: white;
  padding: 30px 30px;
}
.tabs-sticky .tabs-item {
  padding: 0px 20px;
  position: relative;
  max-height: 22px;
}
.tabs-sticky .tabs-item::before {
  content: counter(my-tabs-counter);
  counter-increment: my-tabs-counter;
  position: absolute;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  left: -9.5px;
  top: 0;
  border: solid 1.5px #d7dce1;
  background-color: #fff;
  font-size: 14px;
  font-weight: bold;
  color: #878c96;
  text-align: center;
  font-family: "DB Screen Sans", Roboto, "Helvetica Neue", sans-serif;
}
.tabs-sticky .tabs-item-selected a {
  color: #ec0016 !important;
}
.tabs-sticky .tabs-item-valid::before,
.tabs-sticky .tabs-item-filled::before {
  background-color: #878c96;
  color: #fdfdfd;
}
.tabs-sticky .tabs-item-selected::before {
  border-color: #ec0016;
  background-color: #ec0016;
  color: #fdfdfd;
}
.tabs-sticky .tabs-line {
  padding: 0 0 20px 20px;
  margin: 4px 0;
  border-left: 2px solid #d7dce1;
  height: 42px;
}

/* Main section */
.section-box {
  flex-grow: 1;
  overflow-y: auto;
  background-color: #fff;
}

section {
  width: 90%;
}

/* Header/Footer */
.dialog-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
  overflow: hidden;
}

.grid-container-flex {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

/* Input and Label */
.mandatory {
  margin: 0 auto 0 20px;
}

.mandatory-text {
  white-space: nowrap;
  padding-right: 50px;
}

input:not([type=checkbox]) {
  background-color: #f0f3f5;
  height: 50px;
}

.elm-label-margin {
  margin: 10px 0;
}

.elm-select {
  width: 100%;
  height: 51px;
  padding: 0 0 6px 5px;
  background-color: #F0F3F5;
}

/* Miscellaneous width helpers */
.div-with-20,
.div-with-25,
.div-with-33,
.div-with-50,
.div-with-80,
.div-with-98_5,
.div-with-100 {
  width: 20%, 25%, 33%, 50%, 80%, 98.5%, 100% !important;
}

.button-in-row {
  margin-top: 15px !important;
}

.div-with-button-in-row {
  width: 5% !important;
}

/* Icon margin adjustment */
.icon-margin-top-1rem {
  margin-top: 1rem;
}

.icon-block {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 15px;
  top: 30px;
}
.icon-block .icon {
  position: absolute;
}

db-button {
  display: flex;
  padding: 10px;
  gap: 20px;
}

.dialog-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
  overflow: hidden;
}

.grid-container-flex {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
