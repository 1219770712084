import { inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RailOrder, WagonInformation } from '../../../models/api-railorder';
import { NewOrderWagonDetailDialogComponent } from '../new-order-wagon-detail-dialog/new-order-wagon-detail-dialog.component';
import { NEW_ORDER_DAILOG_WIDTH } from '../../../service/new-order-dialog.service';
import { WagonDataCommunicationService } from './wagon-data-communication.service';

@Injectable({
  providedIn: 'root'
})
export class NewOrderWagonDetailDialogService {

  private wagonDataCommunicationService: WagonDataCommunicationService = inject(WagonDataCommunicationService);

  constructor(private dialog: MatDialog) { }

  public openWagonDetailDialog(railOrder: RailOrder, idx: number): MatDialogRef<NewOrderWagonDetailDialogComponent> {
    this.wagonDataCommunicationService.changeWagonInformation(railOrder.wagonInformation[idx], this.constructor.name);
    return this.dialog.open(NewOrderWagonDetailDialogComponent, {data: {railOrder: railOrder, idx: idx}, width: (NEW_ORDER_DAILOG_WIDTH - 100) + 'px', height: '90vh'});
  }
}
