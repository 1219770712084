import { AfterViewInit, ChangeDetectorRef, Component, inject, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { initalWagonInformation, RailOrder, WagonInformation } from '../../../models/api-railorder';
import { FormBuilder, FormGroup } from '@angular/forms';
import { WagonDataCommunicationService } from '../service/wagon-data-communication.service';

@Component({
  selector: 'app-new-order-wagon-detail-dialog',
  templateUrl: './new-order-wagon-detail-dialog.component.html',
  styleUrl: './new-order-wagon-detail-dialog.component.scss'
})
export class NewOrderWagonDetailDialogComponent implements OnInit, AfterViewInit {

  protected formGroup: FormGroup;

  protected isAdditionalInput: boolean;
  protected isDangerousGoods: boolean;
  protected isCustomerReference: boolean;
  protected isSealingList: boolean;
  protected isLoadingTacklesList: boolean;
  protected isAuthorizationList: boolean;
  
  protected wagonInformation: WagonInformation;
  protected railOrder: RailOrder;

  private wagonDataCommunicationService: WagonDataCommunicationService = inject(WagonDataCommunicationService);

  constructor(
    @Inject(MAT_DIALOG_DATA) data: {railOrder: RailOrder, idx: number},
    private dialogRef: MatDialogRef<NewOrderWagonDetailDialogComponent>, 
    private fb: FormBuilder,
    private cd: ChangeDetectorRef
  ) {
    this.wagonInformation = data.railOrder.wagonInformation[data.idx]; // ? data.wagonInformation : initalWagonInformation();
    this.railOrder = data.railOrder;
  }

  ngOnInit(): void {
    // this.wagonDataCommunicationService.changeWagonInformation(this.wagonInformation, this.constructor.name);
    this.wagonDataCommunicationService.currentWagonInformation$.subscribe({
      next: obj => {
        if(obj.componentName == this.constructor.name) {
          return;
        }
        this.wagonInformation = obj.wagonInformation;
        console.log("wagonInformation changes read in " + this.constructor.name, this.wagonInformation);
      }
    });
    this.createForm();
  }

  ngAfterViewInit(): void {
      this.cd.detectChanges();
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      numberOfWagons: [''],
      wagonInformationList: this.fb.array([]),
      customerReferenceList: this.fb.array([]),
      sealingList: this.fb.array([]),
      loadingTacklesList: this.fb.array([]),
      authorizationList: this.fb.array([]) // exceptionalConsignments
    });
  }
  
  protected confirm() {
    console.log("this.wagonInformation in confirm()", this.wagonInformation);
    this.dialogRef.close(this.wagonInformation);
  }

  protected cancel() {
    this.dialogRef.close(null);
  }

  protected toggleAuthorization(): void {
    this.isAuthorizationList = !this.isAuthorizationList;
  }
  
  protected toggleAdditionalInput(): void {
    this.isAdditionalInput = !this.isAdditionalInput;
  }

  protected toggleDangerousGoods(): void {
    this.isDangerousGoods = !this.isDangerousGoods;
  }

  protected toggleCustomerReference(): void {
    this.isCustomerReference = !this.isCustomerReference;
  }

  protected toggleSealingList(): void {
    this.isSealingList = !this.isSealingList;
  }

  protected toggleLoadingTackles(): void {
    this.isLoadingTacklesList = !this.isLoadingTacklesList
  }
}
